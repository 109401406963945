import {ReportEntry} from "./reportEntry";

export class ReportEntryList extends Array<ReportEntry> {

    static fromJson(json: any[]): ReportEntryList {
        const obj = new ReportEntryList();

        json.forEach(function (item: any) {
            obj.push(ReportEntry.fromJson(item));
        });

        return obj;
    }
}