import {KpiCategoryKeys} from "./kpiCategoryKeys";
import {KPI_KEYS_ORDER} from "./kpiKeysOrder";
import {KpiPrognosis} from "./kpiPrognosis";

export function sort(kpis: KpiPrognosisList) {

    const sortedKpis = new KpiPrognosisList();

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: KpiPrognosis) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}

export class KpiPrognosisList extends Array<KpiPrognosis> {

    static fromJson(json: any[]): KpiPrognosisList {
        let obj = new KpiPrognosisList();

        json.forEach(function (item: any) {
            obj.push(KpiPrognosis.fromJson(item));
        });

        // Sort according to UI requirements.
        obj = sort(obj);

        return obj;
    }

    getCategoryName(categoryKey: KpiCategoryKeys): string {
        let name = '';

        this.forEach(function (kpi: KpiPrognosis) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKeys): KpiPrognosisList {
        const results: KpiPrognosisList = new KpiPrognosisList();

        this.forEach(function (kpi: KpiPrognosis) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}