import {Product} from "./product";

export class ProductList extends Array<Product> {

    static fromJson(json: any[]): ProductList {
        const obj = new ProductList();

        json.forEach(function (item: any) {
            obj.push(Product.fromJson(item));
        });

        return obj;
    }
}