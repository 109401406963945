import {Country} from "./country";

export class CountryList extends Array<Country> {

    static fromJson(json: any[]): CountryList {
        const obj = new CountryList();

        json.forEach(function (item: any) {
            obj.push(Country.fromJson(item));
        });

        return obj;
    }
}