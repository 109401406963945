export class ApiEndpoints {
    // eslint-disable-next-line no-undef
    static readonly apiUrl = process.env.REACT_APP_API_URL;

    static readonly countriesList = `${ApiEndpoints.apiUrl}/countries/list`;
    static readonly currenciesList = `${ApiEndpoints.apiUrl}/currencies/list`;
    static readonly kpisList = `${ApiEndpoints.apiUrl}/kpis/list`;
    static readonly productsList = `${ApiEndpoints.apiUrl}/products/list`;

    static readonly companiesCreate = `${ApiEndpoints.apiUrl}/companies/create`;
    static readonly companiesList = `${ApiEndpoints.apiUrl}/companies/list`;
    static readonly companiesUpdate = `${ApiEndpoints.apiUrl}/companies/update`;

    static readonly companyReportsCreate = `${ApiEndpoints.apiUrl}/companies/reports/create`;
    static readonly companyReportsList = `${ApiEndpoints.apiUrl}/companies/reports/list`;
    static readonly companyReportsUpload = `${ApiEndpoints.apiUrl}/companies/reports/upload`;

    static readonly companyMetricsBenchmarksGet = `${ApiEndpoints.apiUrl}/companies/metrics/benchmarks/get`;
    static readonly companyMetricsDownload = `${ApiEndpoints.apiUrl}/companies/metrics/download`;
    static readonly companyMetricsKpisGet = `${ApiEndpoints.apiUrl}/companies/metrics/kpis/get`;
    static readonly companyMetricsKpisPrognosesGet = `${ApiEndpoints.apiUrl}/companies/metrics/kpis/prognoses/get`;

    static readonly industryList = `${ApiEndpoints.apiUrl}/industries/list`;
    static readonly industryMetricsBenchmarksGet = `${ApiEndpoints.apiUrl}/industries/metrics/benchmarks/get`;
    static readonly industryMetricsKpisAggregatesGet = `${ApiEndpoints.apiUrl}/industries/metrics/kpis/aggregates/get`;
    static readonly industryMetricsKpisPrognosesGet = `${ApiEndpoints.apiUrl}/industries/metrics/kpis/prognoses/get`;

    static readonly userSignIn = `${ApiEndpoints.apiUrl}/user/access-token`;
    static readonly userChangePassword = `${ApiEndpoints.apiUrl}/user/change-password`;
    static readonly userContactUs = `${ApiEndpoints.apiUrl}/user/contact-us`;
    static readonly userGet = `${ApiEndpoints.apiUrl}/user/get`;
    static readonly userRequestEmailVerification = `${ApiEndpoints.apiUrl}/user/request-email-verification`;
    static readonly userRequestPasswordChange = `${ApiEndpoints.apiUrl}/user/request-password-change`;
    static readonly userSignUp = `${ApiEndpoints.apiUrl}/user/sign-up`;
    static readonly userVerifyEmail = `${ApiEndpoints.apiUrl}/user/verify-email`;

    static readonly userCancelSubscription = `${ApiEndpoints.apiUrl}/user/cancel-subscription`;
    static readonly userInitializeSubscription = `${ApiEndpoints.apiUrl}/user/initialize-subscription`;

    static readonly userIntegrationAuthUrl = `${ApiEndpoints.apiUrl}/user/request-app-authorization-url`;
    static readonly userIntegrationAccessToken = `${ApiEndpoints.apiUrl}/user/request-app-access-token`;

    static readonly appIntegrationBalanceSheet = `${ApiEndpoints.apiUrl}/user/app-integration/get-balance-sheet`;
}