import * as React from 'react';
import {Cell, Label, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {KpiInfoList} from "../../api/metrics/kpiInfoList";
import {KpiPrognosis} from "../../api/metrics/kpiPrognosis";
import {KpiPrognosisMap} from "../../api/metrics/kpiPrognosisMap";
import BlurView from "../../components/BlurView";
import {ChartData} from "../../components/charts/chartData";
import {ChartDataList} from "../../components/charts/chartDataList";
import {Strings} from "../../locales/strings";
import Colors from "../../utils/colors";
import {PageStatus} from "../pageStatus";
import {ErrorCard} from "./ErrorCard";
import {LoadingCard} from "./LoadingCard";

function Chart({data}: { data: ChartDataList }) {
    return (
        <ResponsiveContainer width="100%" height={160}>
            <PieChart width={400} height={300}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx="50%"
                    cy="80%"
                    innerRadius={80}
                    outerRadius={100}
                    fill="#white"
                    labelLine={true}
                    stroke="black"
                    strokeWidth={1}
                    paddingAngle={3}
                >
                    <Label
                        value={data[0].value}
                        position="center"
                        fill="grey"
                        dy={-20}
                        style={{
                            fontSize: "32px",
                            fontWeight: "bold"
                        }}
                    />

                    {data.map((entry: ChartData, index: any) => (
                        <Cell
                            key={`cell-${index}`}
                            stroke={index === 0 ? "none" : "none"}
                            strokeWidth={1}
                            fill={entry.color}/>
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default function CcpChart(
    {status, data, kpiInfoList, kpiKey}: {
        status: PageStatus,
        data: KpiPrognosisMap | null,
        kpiInfoList: KpiInfoList | null,
        kpiKey: string
    }) {

    let title = 'Generating analysis...';
    let template = <></>;

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && data !== null) {

        if (data.has(kpiKey)) {
            const kpiPrognosis = data.get(kpiKey) || new KpiPrognosis();
            title = `${kpiPrognosis.kpiName} CCP Score`;

            // Need two values which sum = 100.
            const ccp = kpiPrognosis.kpiCcpValue || 0;
            const remainder = (100 - ccp);
            const chartData = new ChartDataList();

            const pd1 = new ChartData();
            pd1.name = 'CCP';
            pd1.value = ccp;
            pd1.color = Colors.primary;
            chartData.push(pd1);

            const pd2 = new ChartData();
            pd2.name = '';
            pd2.value = remainder;
            pd2.color = Colors.primaryMuted;
            chartData.push(pd2);

            template = <Chart data={chartData}/>;
        } else {
            const kpiName = kpiInfoList?.getKpiName(kpiKey) || '';
            title = `Upgrade to access ${kpiName} CCP Score`;

            // Data does not exist because it is not authorized.
            // Therefore, use fake data instead for blurring.
            const ccp = 50;
            const remainder = 100 - ccp;
            const chartData = new ChartDataList();

            const pd1 = new ChartData();
            pd1.name = 'CCP';
            pd1.value = ccp;
            pd1.color = Colors.primary;
            chartData.push(pd1);

            const pd2 = new ChartData();
            pd2.name = '';
            pd2.value = remainder;
            pd2.color = Colors.primaryMuted;
            chartData.push(pd2);

            template = <BlurView><Chart data={chartData}/></BlurView>;
        }

        // If key is missing, then it is not authorized.
        // if (Object.hasOwn(data, kpiKey)) {
        //     title = `${kpiName} CCP Score`;
        //
        //     // Need two values which sum = 100.
        //     const ccp = data[kpiKey].kpiCcpValue;
        //     const remainder = (100 - ccp);
        //     const chartData = new ChartDataList();
        //
        //     const pd1 = new ChartData();
        //     pd1.name = 'CCP';
        //     pd1.value = ccp;
        //     pd1.color = Colors.primary;
        //     chartData.push(pd1);
        //
        //     const pd2 = new ChartData();
        //     pd2.name = '';
        //     pd2.value = remainder;
        //     pd2.color = Colors.primaryMuted;
        //     chartData.push(pd2);
        //
        //     template = <Chart data={chartData}/>;
        //
        // } else {
        //     title = `Upgrade to access ${kpiName} CCP Score`;
        //
        //     // Data does not exist because it is not authorized.
        //     // Therefore, use fake data instead for blurring.
        //     const ccp = 50;
        //     const remainder = 100 - ccp;
        //     const chartData = new ChartDataList();
        //
        //     const pd1 = new ChartData();
        //     pd1.name = 'CCP';
        //     pd1.value = ccp;
        //     pd1.color = Colors.primary;
        //     chartData.push(pd1);
        //
        //     const pd2 = new ChartData();
        //     pd2.name = '';
        //     pd2.value = remainder;
        //     pd2.color = Colors.primaryMuted;
        //     chartData.push(pd2);
        //
        //     template = <BlurView><Chart data={chartData}/></BlurView>;
        // }

    } else if (status === PageStatus.HasError) {
        title = 'Error';
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}
