import {ReportStatement} from "./reportStatement";

export class ReportStatementList extends Array<ReportStatement> {

    static fromJson(json: any[]): ReportStatementList {
        const obj = new ReportStatementList();

        json.forEach(function (item: any) {
            obj.push(ReportStatement.fromJson(item));
        });

        return obj;
    }
}