import {UserEntitlementList} from "./userEntitlementList";
import {UserSubscription} from "./userSubscription";

export class User {
    userId: string = '';
    entitlements: UserEntitlementList = new UserEntitlementList();
    subscription: UserSubscription = new UserSubscription();

    static fromJson(json: any): User | null {
        if (Object.keys(json).length === 0) {
            return null;
        }

        const obj = new User();

        obj.userId = json.user_id;

        if ('entitlements' in json) {
            obj.entitlements = UserEntitlementList.fromJson(json.entitlements);
        }

        if ('subscription' in json) {
            obj.subscription = UserSubscription.fromJson(json.subscription);
        }

        return obj;
    }

    toJson() {
        return {
            "user_id": this.userId,
            "entitlements": this.entitlements.toJson(),
            "subscription": this.subscription.toJson()
        };
    }
}