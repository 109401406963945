import * as React from 'react';
import {PageStatus} from "../pages/pageStatus";

export default function FormSuccess(
    {status, message}: {
        status: PageStatus,
        message: string | undefined | null
    }) {

    let template;

    if (message
        && status !== PageStatus.HasError
        && status !== PageStatus.RequiresAuth
        && status !== PageStatus.UserEmailUnverified) {

        template = (
            <div className="form-error alert alert-success">
                <div className="row">
                    <div className="col-auto">
                        <i className="bi bi-check-circle"></i>
                    </div>
                    <div className="col ps-0">
                        {message}
                    </div>
                </div>
            </div>
        )
        ;
    } else {
        template = (<></>);
    }

    return (
        <>{template}</>
    );
}