import * as React from 'react';
import {
    ComposedChart,
    Label,
    Legend,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {KpiInfoList} from "../../api/metrics/kpiInfoList";
import BlurView from "../../components/BlurView";
import {Strings} from "../../locales/strings";
import Colors from "../../utils/colors";
import {PageStatus} from "../pageStatus";
import {SeddaData} from "./chartUtils";
import {ErrorCard} from "./ErrorCard";
import {LoadingCard} from "./LoadingCard";

const TriangleReferenceArea = (props: any) => {

    let p;

    if (props.direction === 'up') {
        p = props.x + "," + props.y + " " + (props.x + props.width) + "," + props.y + " " + props.x + "," + (props.y + props.height);
    } else {
        const p1 = `${props.x},${(props.y + props.height)}`;
        const p2 = `${(props.x + props.width)},${props.y}`;
        const p3 = `${(props.x + props.width)},${(props.y + props.height)}`;

        p = `${p1} ${p2} ${p3}`;
    }

    return (
        <polygon
            points={p}
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
    );
};

function getPlaceholderData(): SeddaData {

    const seddaData = new SeddaData();
    seddaData.xDomain = [0, 2.0];
    seddaData.yDomain = [0, 2.0];
    seddaData.xRef = 1;
    seddaData.yRef = 1;
    seddaData.companyKpiValue = 1.25;
    seddaData.companyKpiPrognosisValue = 1.6;
    seddaData.benchmarkKpiValue = 1.65;
    seddaData.benchmarkKpiPrognosisValue = 1.35;

    return seddaData;
}

function Chart(
    {data}: {
        data: SeddaData | null | undefined
    }) {

    if (data === null || data === undefined) {
        return <></>;
    }

    const companyData: any[] = [{
        'current_value': data.companyKpiValue,
        'predicted_value': data.companyKpiPrognosisValue
    }];

    const benchmarkData: any = [{
        'current_value': data.benchmarkKpiValue,
        'predicted_value': data.benchmarkKpiPrognosisValue
    }];

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                    width={400}
                    height={400}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <Legend
                        iconSize={12}
                        align="center"
                        verticalAlign="bottom"
                        wrapperStyle={{position: 'relative', marginTop: '-5px'}}
                    >
                    </Legend>

                    <XAxis
                        type="number"
                        dataKey="current_value"
                        domain={data.xDomain}
                        stroke={Colors.textMedium}
                        tick={{fontSize: 12}}
                        tickCount={5}
                    >
                        <Label dx={-10} dy={20}>Current Values</Label>
                    </XAxis>

                    <YAxis
                        type="number"
                        dataKey="predicted_value"
                        domain={data.yDomain}
                        stroke={Colors.textMedium}
                        tick={{fontSize: 12}}
                        tickCount={5}>
                        <Label transform="rotate(270 40 145)">Prognosis</Label>
                    </YAxis>

                    <Tooltip/>

                    <ReferenceArea
                        x1={data.xDomain[0]}
                        x2={data.xRef}
                        y1={data.yRef}
                        y2={data.yDomain[1]}
                        fill="green"
                        fillOpacity={0.1}
                        stroke="none"
                    >
                        <Label dx={10} position="insideLeft">Encouraging</Label>
                    </ReferenceArea>

                    <ReferenceArea
                        x1={data.xRef}
                        x2={data.xDomain[1]}
                        y1={data.yRef}
                        y2={data.yDomain[1]}
                        fill="green"
                        fillOpacity={0.5}
                        stroke="none"
                        shape={<TriangleReferenceArea direction="up"/>}
                    >
                        <Label dx={10} dy={10} position="insideTopLeft">Sound</Label>
                    </ReferenceArea>

                    <ReferenceArea
                        x1={data.xRef}
                        x2={data.xDomain[1]}
                        y1={data.yRef}
                        y2={data.yDomain[1]}
                        fill="yellow"
                        fillOpacity={0.5}
                        stroke="none"
                        shape={<TriangleReferenceArea direction="down"/>}
                    >
                        <Label dx={-10} position="insideBottomRight">Disquieting</Label>
                    </ReferenceArea>

                    <ReferenceArea
                        x1={data.xRef}
                        x2={data.xDomain[1]}
                        y1={data.yRef}
                        y2={data.yDomain[0]}
                        fill="orange"
                        fillOpacity={0.3}
                        stroke="none"
                    >
                        <Label dx={-10} position="insideRight">Dismaying</Label>
                    </ReferenceArea>

                    <ReferenceArea
                        x1={data.xDomain[0]}
                        x2={data.xRef}
                        y1={data.yDomain[0]}
                        y2={data.yRef}
                        fill="red"
                        fillOpacity={0.1}
                        stroke="none"
                        shape={<TriangleReferenceArea direction="down"/>}
                    >
                        <Label dx={-10} position="insideRight">Ailing</Label>
                    </ReferenceArea>

                    <ReferenceArea
                        x1={data.xDomain[0]}
                        x2={data.xRef}
                        y1={data.yDomain[0]}
                        y2={data.yRef}
                        fill="green"
                        fillOpacity={0.1}
                        stroke="none"
                        shape={<TriangleReferenceArea direction="up"/>}
                    >
                        <Label dx={10} position="insideLeft"></Label>
                    </ReferenceArea>

                    <ReferenceLine x={data.xRef} stroke="gray"/>
                    <ReferenceLine y={data.yRef} stroke="gray"/>

                    <ReferenceLine stroke="gray" segment={[{
                        x: data.xDomain[0],
                        y: data.yDomain[0]
                    }, {
                        x: data.xDomain[1],
                        y: data.yDomain[1]
                    }]}
                    />

                    <Scatter name="Company" data={companyData} fill={Colors.primary}/>
                    <Scatter name="Industry" data={benchmarkData} fill={Colors.secondary}/>

                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}

export default function SeddaChart(
    {status, data, kpiInfoList, kpiKey, isAuth}: {
        status: PageStatus,
        data: SeddaData | null | undefined,
        kpiInfoList: KpiInfoList | null,
        kpiKey: string,
        isAuth: boolean
    }) {

    let template = <></>;
    let title = '';

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        title = 'Generating analysis...';
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && data !== null && data !== undefined) {
        const kpiName = kpiInfoList?.getKpiNameLong(kpiKey) || '';

        if (isAuth) {
            title = `${kpiName}`;
            template = <Chart data={data}/>;
        } else {
            title = `Upgrade to access ${kpiName}`;
            template = <BlurView><Chart data={getPlaceholderData()}/></BlurView>;
        }

    } else if (status === PageStatus.HasError) {
        title = 'Error';
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}
