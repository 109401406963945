import {ReportStatement} from "./reportStatement";
import {ReportStatementList} from "./reportStatementList";

export class Report {
    periodEndDate: string = '';
    quarter: string = '';
    statements: ReportStatementList = new ReportStatementList();

    static fromJson(json: any): Report {
        const obj = new Report();

        obj.periodEndDate = json.period_end_date;
        obj.quarter = json.quarter;

        const stmts = json.statements;

        stmts.forEach(function (stmt: any) {
            obj.statements.push(ReportStatement.fromJson(stmt));
        });

        return obj;
    }

    getStatement(statementKey: string): ReportStatement | null {
        let stmt = null;

        this.statements.forEach(function (s: ReportStatement) {
            if (s.statementKey === statementKey) {
                stmt = s;
                return;
            }
        });

        return stmt;
    }

    getBalanceSheetStatement(): ReportStatement | null {
        return this.getStatement('balance_sheet');
    }

    getCashflowStatement(): ReportStatement | null {
        return this.getStatement('cashflow_statement');
    }

    getIncomeStatement(): ReportStatement | null {
        return this.getStatement('income_statement');
    }
}