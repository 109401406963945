export class IndustryKpiAggregatesGetParams {
    sectorKey: string = '';
    periodEndDate: string = '';
    aggKeys: string[] = [];

    toJson() {
        return {
            "sector_key": this.sectorKey,
            "period_end_date": this.periodEndDate,
            "agg_keys": this.aggKeys
        };
    }
}