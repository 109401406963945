import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {CompanyList} from "../../api/companies/companyList";
import {useAuth} from "../../AuthProvider";
import {useCompanyList} from "../../CompanyProvider";
import {PageEndpoints} from "../pageEndpoints";

export default function UserSignOut() {

    const navigate = useNavigate();
    const {signOut} = useAuth();
    const {setCompanies, setCurrentCompanyId} = useCompanyList();

    useEffect(() => {
        setCompanies(new CompanyList());
        setCurrentCompanyId('');

        signOut();
        navigate(PageEndpoints.userSignIn);
    });

    return (
        <></>
    );
}