import {ProductFeature} from "./productFeature";

export class ProductFeatureList extends Array<ProductFeature> {

    static fromJson(json: any[]): ProductFeatureList {
        const obj = new ProductFeatureList();

        json.forEach(function (item: any) {
            obj.push(ProductFeature.fromJson(item));
        });

        return obj;
    }
}