import {createContext} from 'react';
import {CompanyList} from "./api/companies/companyList";

export interface UserCompany {
    getCompanies: () => CompanyList;
    setCompanies: (companies: CompanyList) => void;

    getCurrentCompanyId: () => string;
    setCurrentCompanyId: (companyId: string) => void;
}

export const defaultContext: UserCompany = {
    getCompanies: () => new CompanyList(),

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCompanies: (companies) => {
    },

    getCurrentCompanyId: () => '',

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentCompanyId: (companyId) => {
    }
};

const CompanyContext = createContext(defaultContext);

export {CompanyContext};

