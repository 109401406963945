import {AppConstants} from '../appConstants';

export class PageTitles {
    static readonly home = `Financial Prognosis - ${AppConstants.appName}`;
    static readonly publicContactUs = `Contact Us - ${AppConstants.appName}`;
    static readonly publicFaqs = `FAQs - ${AppConstants.appName}`;
    static readonly publicPricing = `Plans & Pricing - ${AppConstants.appName}`;
    static readonly publicPrivacy = `Privacy Policy - ${AppConstants.appName}`;
    static readonly publicTerms = `Terms of Use - ${AppConstants.appName}`;

    static readonly dashboard = `Dashboard - ${AppConstants.appName}`;

    static readonly company = `Company - ${AppConstants.appName}`;

    static readonly reports = `Financial Reports - ${AppConstants.appName}`;
    static readonly reportsCreate = `Add Report - ${AppConstants.appName}`;

    static readonly performanceAnalysis = `Performance Analysis - ${AppConstants.appName}`;

    static readonly userAccount = `Account - ${AppConstants.appName}`;
    static readonly userAppIntegration = `App Integration - ${AppConstants.appName}`;

    static readonly userEmailVerificationRequest = `Request Email Verification - ${AppConstants.appName}`;
    static readonly userEmailVerificationStatus = `Verify Email - ${AppConstants.appName}`;
    static readonly userForgotPassword = `Forgot Password - ${AppConstants.appName}`;
    static readonly userResetPassword = `Reset Password - ${AppConstants.appName}`;
    static readonly userSignIn = `Sign In - ${AppConstants.appName}`;
    static readonly userSignUp = `Sign Up - ${AppConstants.appName}`;

    static readonly userSubscribe = `Get Started - ${AppConstants.appName}`;
}