import * as React from 'react';
import {PageStatus} from "../pages/pageStatus";

export default function SubmitButton(
    {isFormReady, featureStatus, text, additionalClassName = ''}: {
        isFormReady: boolean,
        featureStatus: PageStatus,
        text: string,
        additionalClassName?: string
    }) {

    const isBusy = featureStatus === PageStatus.IsSubmitting;
    let className = 'btn btn-lg btn-primary ';

    if (additionalClassName) {
        className += ' ' + additionalClassName;
    }

    return (
        <button disabled={!isFormReady}
                type="submit"
                className={className}>
            {isBusy &&
                <span className="spinner">
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                </span>
            }
            {text}
        </button>
    );
}