import * as React from "react";
import {useEffect} from "react";
import {useCompanyList} from "../../CompanyProvider";
import {PageTitles} from "../../locales/pageTitles";
import {Analysis} from "./Analysis";

export function AnalysisRoot() {
    const {getCurrentCompanyId} = useCompanyList();
    const companyId = getCurrentCompanyId();

    useEffect(() => {
        document.title = PageTitles.performanceAnalysis;
    }, []);

    // Use key to ensure tree is different for each company.
    return (
        <div className="p-4 content analysis">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xxl-10">
                    <Analysis key={companyId} companyId={companyId} />
                </div>
            </div>
        </div>
    );
}