import * as React from "react";
import {Strings} from "../../locales/strings";

export function LoadingCard(
    {description1, description2}: {
        description1?: string | null | undefined,
        description2?: string | null | undefined
    }) {

    let message1 = Strings.ERROR_SOMETHING;
    let message2 = '\u00A0';

    if (description1) {
        message1 = description1;
    }

    if (description2) {
        message2 = description2;
    }

    return (
        <>
            <h5 className="card-title">
                <div>{message1}</div>
                <div>{message2}</div>
            </h5>
            <div className="charts-loading text-center">
                    <span className="spinner-border spinner-border-lg text-primary"
                          role="status"
                          aria-hidden="true"></span>
            </div>
        </>
    );
}
