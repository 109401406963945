export class CompanyBenchmarksGetParams {
    companyId: string = '';
    periodEndDate: string = '';

    toJson() {
        return {
            "company_id": this.companyId,
            "period_end_date": this.periodEndDate
        };
    }
}
