import {useEffect, useState} from "react";
import {ApiEndpoints} from "../api/apiEndpoints";
import {CompanyList} from "../api/companies/companyList";
import {useAuth} from "../AuthProvider";
import {useCompanyList} from "../CompanyProvider";
import {postData} from "../api/postData";
import {getHeaders} from "../api/requestHeaders";
import {PageStatus} from "../pages/pageStatus";

export default function useCompanySave(companyId: string) {
    const {getToken} = useAuth();
    const {setCompanies, setCurrentCompanyId} = useCompanyList();

    const [status, setStatus] = useState(PageStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (data: any) => {
            if (status !== PageStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(PageStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(PageStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);
                    setFormData({});

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            let url = ApiEndpoints.companiesCreate;

            if (companyId) {
                url = ApiEndpoints.companiesUpdate;
                data['company_id'] = companyId;
            }

            const companyResults = await postData(url, data, headers, signal);

            if (doExitEarly(companyResults)) {
                return;
            }

            // Get ID from new company.
            const company = companyResults.results;
            const newCompanyId = company[0].company_id;

            const companyListResults = await postData(ApiEndpoints.companiesList, {}, headers);

            if (doExitEarly(companyListResults)) {
                return;
            }

            // Update after any changes to new or existing companies.
            const companyList = companyListResults.results;
            setCompanies(CompanyList.fromJson(companyList));

            // Any time we add a new company, we switch to this company.
            setCurrentCompanyId(newCompanyId);
            setStatus(PageStatus.HasSubmitted);
        };

        postForm(formData).then();

        return () => {
            controller.abort();
        };

    }, [status, formData, companyId, getToken, setCompanies, setCurrentCompanyId]);

    const saveCompany = (data: any) => {
        setStatus(PageStatus.IsSubmitting);
        setMessage('');
        setValidationErrors([]);

        setFormData(data);
    };

    return {
        status, message, validationErrors, saveCompany
    };
}