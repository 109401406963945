import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {CustomizedState} from '../../utils/customizedState';
import {getFieldToFocus} from "../../utils/pageUtils";
import {PageEndpoints} from '../pageEndpoints';
import FormError from "../../components/FormError";
import Logo from '../../components/Logo';
import SubmitButton from "../../components/SubmitButton";
import {PageStatus} from "../pageStatus";
import {PageTitles} from '../../locales/pageTitles';
import {userEmailValidationSchema} from "../../api/user/userValidationSchema";
import useUserVerifyRequest from "../../hooks/useUserVerifyRequest";

export default function UserEmailVerificationStatus() {

    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as CustomizedState;

    const {status, message, validationErrors, submitForm, formData} = useUserVerifyRequest();

    useEffect(() => {
        document.title = PageTitles.userEmailVerificationRequest;
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userEmailValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    /* Set defaults, if they exist. */
    useEffect(() => {
        state &&
        reset({
            email_address: state?.emailAddress
        }, {keepDefaultValues: true});
    }, [state, reset]);

    useEffect(() => {
        const defaultField = 'email_address';

        if (status === PageStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, defaultField);
            setFocus(fieldName);

        } else if (status === PageStatus.IsReady) {
            setFocus(defaultField);
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            navigate(PageEndpoints.userEmailVerificationStatus, {state: {'emailAddress': formData.email_address}});
        }
    }, [navigate, status, formData]);

    const onSubmitHandler = async (data: any) => {
        submitForm(data);
    };

    return (
        <div className="container account py-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="mt-5">
                        <Logo/>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">Request email verification code.</h1>
                            <FormError status={status} message={message}/>
                            <div className="row">
                                <div className="col">
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <fieldset id="me" disabled={status === PageStatus.IsSubmitting}>
                                            <div className="form-floating">
                                                <input {...register('email_address')} autoFocus type="text"
                                                       className="form-control" id="floatingEmail"
                                                       placeholder="name@example.com"/>
                                                <label htmlFor="floatingEmail">Email address</label>
                                                <small className="text-danger">
                                                    {errors?.email_address?.message?.toString()}
                                                </small>
                                            </div>
                                            <div>
                                                <SubmitButton
                                                    isFormReady={isFormReady}
                                                    featureStatus={status}
                                                    text="Send code"
                                                    additionalClassName="w-100"
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <Link to={PageEndpoints.userSignIn} className="float-start">Sign in</Link>
                                </div>
                                <div className="col-8">
                                    <Link to={PageEndpoints.userChangePasswordRequest} className="float-end">Forgot
                                        password</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}