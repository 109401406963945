import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {useLocation, useNavigate} from "react-router-dom";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {Strings} from "../../locales/strings";
import {formatCurrency, formatPercentage} from "../../utils/numberUtils";
import {PageEndpoints} from "../pageEndpoints";
import FormError from "../../components/FormError";
import Logo from "../../components/Logo";
import SubmitButton from "../../components/SubmitButton";
import {PageStatus} from "../pageStatus";
import ProductCard from "../public/ProductCard";
import {Product} from "../../api/products/product";
import {ProductList} from "../../api/products/productList";
import {ProductPrice} from "../../api/products/productPrice";
import usePostData from "../../hooks/usePostData";
import useUserSubscribe from "../../hooks/useUserSubscribe";
import {SubscriptionInterval} from "../../api/products/subscriptionInterval";

function getPrice(
    productList: ProductList | null,
    productId: string,
    recurringInterval: string): ProductPrice | null {

    if (productList === null) {
        return null;
    }

    if (productId === '') {
        return null;
    }

    let selectedPrice: ProductPrice | null = null;

    productList.forEach(function (product: Product) {
        if (product.productId === productId) {
            product.prices.forEach(function (price: ProductPrice) {

                // There is only a monthly price for the free plan.
                if (product.productName.toLowerCase().includes('free')) {
                    selectedPrice = price;
                    return;
                }

                if (price.recurringInterval === recurringInterval) {
                    selectedPrice = price;
                    return;
                }
            });
        }
    });

    return selectedPrice;
}

function getProduct(
    productList: ProductList | null,
    productId: string): Product | null {

    if (productList === null) {
        return null;
    }

    let product: Product | null = null;

    productList.forEach(function (prd: Product) {
        if (prd.productId === productId) {
            product = prd;
        }
    });

    return product;
}

function getFreeProductId(
    productList: ProductList | null) {

    if (productList === null) {
        return null;
    }

    let productId: string | null = null;

    productList.forEach(function (product: Product) {
        if (product.productName.toLowerCase().includes('free')) {
            productId = product.productId;
        }
    });

    return productId;
}

export default function UserSubscribeForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const firstRun = useRef<boolean>(true);

    const {status, message, subscribeUser} = useUserSubscribe();
    const {status: productListStatus, submitForm, results: productListResults} = usePostData();
    const [productList, setProductList] = useState<ProductList | null>(null);

    let productIdState = location?.state?.productId || '';

    useEffect(() => {
        if (productList === null && productListStatus === PageStatus.IsReady) {
            submitForm(ApiEndpoints.productsList, {});
        }
    }, [productListStatus, productList, submitForm]);

    useEffect(() => {
        if (productListResults !== null) {
            const productList = ProductList.fromJson(productListResults);
            setProductList(productList);
        }
    }, [productListResults]);

    const [activeTab, setActiveTab] = useState('choosePlan');
    const [recurringInterval, setRecurringInterval] = useState(SubscriptionInterval.MONTH);
    const [productId, setProductId] = useState(productIdState);
    const [isCardComplete, setIsCardComplete] = useState(false);

    // If product ID was passed (pre-selected), go to payment details.
    useEffect(() => {
        if (firstRun.current) {
            if (productId) {
                setActiveTab('paymentDetails');
            }

            firstRun.current = false;
        }
    }, [productId]);

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            navigate(PageEndpoints.home, {
                state: {
                    'message': Strings.USER_SUBSCRIPTION_SUCCESS
                }
            });
        }
    }, [status, navigate]);

    const handlePlanPeriodChange = (event: any) => {
        setRecurringInterval(event.target.value);
    };

    const handleWizardButtonClick = (key: string) => {
        setActiveTab(key);
    };

    const handleOnSelectPlan = (productId: string) => {
        setProductId(productId);
    };

    const handleCancelClick = (e: any) => {
        e.preventDefault();
        navigate(PageEndpoints.userSignOut);
    };

    if (!stripe || !elements) {
        return (<></>);
    }

    const handlePaymentChange = (event: any) => {
        setIsCardComplete(event.complete);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const selectedProduct = getProduct(productList, productId);
        const selectedPrice = getPrice(productList, productId, recurringInterval);
        const freeProductId = getFreeProductId(productList);

        if (selectedProduct != null && selectedPrice !== null && freeProductId !== null) {
            const choseFree = selectedProduct.productId === freeProductId;

            subscribeUser(
                selectedProduct.productId,
                selectedPrice.priceId,
                choseFree,
                elements);
        }
    };

    const isBusy = status === PageStatus.IsSubmitting;
    const allowPayment = productId !== '' && !isBusy;
    const allowSubscribe = stripe && elements && !isBusy;
    const selectedProduct = getProduct(productList, productId);
    const selectedPrice = getPrice(productList, productId, recurringInterval);

    const freeProductId = getFreeProductId(productList);
    const planNextTab = productId === freeProductId ? 'review' : 'paymentDetails';
    const reviewPreviousTab = productId === freeProductId ? 'choosePlan' : 'paymentDetails';

    const reviewMessage = recurringInterval === SubscriptionInterval.MONTH
        ? Strings.USER_SUBSCRIPTION_RENEWS_MONTHLY
        : Strings.USER_SUBSCRIPTION_RENEWS_YEARLY;

    const showMonthly = recurringInterval === SubscriptionInterval.MONTH;

    return (
        <form onSubmit={handleSubmit}>
            <div className="container public pricing py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 page-header">
                        <Logo/>
                        <h1 className="mb-4">Get Started with CFOdigital</h1>
                        <p className="text-muted text-center mb-1">We have plans and prices that fit your business
                            perfectly.</p>
                        <FormError status={status} message={message}/>
                    </div>

                    <div className="col-lg-12">
                        <Tabs
                            activeKey={activeTab}
                            defaultActiveKey="choosePlan"
                            id="subscriptionWizard"
                            justify>
                            <Tab eventKey="choosePlan" title="">
                                <div className="row">
                                    <div className="col mb-4">
                                        <h2>Choose your plan.</h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col text-center plan-period mb-4">
                                        <div className="btn-group" role="group">
                                            <input type="radio" className="btn-check" name="plan_period"
                                                   id="plan-period-month"
                                                   autoComplete="off"
                                                   value={SubscriptionInterval.MONTH}
                                                   onChange={handlePlanPeriodChange}
                                                   checked={recurringInterval === SubscriptionInterval.MONTH}/>
                                            <label className="btn btn-outline-light period me-1"
                                                   htmlFor="plan-period-month">
                                                Monthly
                                            </label>

                                            <input type="radio" className="btn-check" name="plan_period"
                                                   id="plan-period-annual"
                                                   autoComplete="off"
                                                   value={SubscriptionInterval.YEAR}
                                                   onChange={handlePlanPeriodChange}
                                                   checked={recurringInterval === SubscriptionInterval.YEAR}/>
                                            <label className="btn btn-outline-light period ms-1"
                                                   htmlFor="plan-period-annual">
                                                Annually
                                                <span className="badge badge-outline-primary ms-2">
                                                    up to {formatPercentage(.2, 0)} off
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-3 col-xxl-2">
                                        {
                                            productList && <ProductCard
                                                product={productList[0]}
                                                showMonthly={showMonthly}
                                                selectedProductId={productId}
                                                onSelect={handleOnSelectPlan}
                                            />
                                        }
                                    </div>
                                    <div className="col-12 mt-4 col-md-6 mt-md-0 col-lg-3 col-xxl-2">
                                        {
                                            productList && <ProductCard
                                                product={productList[1]}
                                                showMonthly={showMonthly}
                                                selectedProductId={productId}
                                                onSelect={handleOnSelectPlan}
                                            />
                                        }
                                    </div>
                                    <div className="col-12 mt-4 col-md-6 col-lg-3 mt-lg-0 col-xxl-2">
                                        {
                                            productList && <ProductCard
                                                product={productList[2]}
                                                showMonthly={showMonthly}
                                                selectedProductId={productId}
                                                onSelect={handleOnSelectPlan}
                                            />
                                        }
                                    </div>
                                    <div className="col-12 mt-4 col-md-6 col-lg-3 mt-lg-0 col-xxl-2">
                                        {
                                            productList && <ProductCard
                                                product={productList[3]}
                                                showMonthly={showMonthly}
                                                selectedProductId={productId}
                                                onSelect={handleOnSelectPlan}
                                            />
                                        }
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col text-center">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary w-25"
                                                onClick={() => handleWizardButtonClick(planNextTab)}
                                                disabled={!allowPayment}>
                                                {isBusy &&
                                                    <span className="spinner">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true">
                                                            </span>
                                                        </span>
                                                }
                                                Continue
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className="btn btn-light w-25"
                                                disabled={isBusy}
                                                onClick={handleCancelClick}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="paymentDetails" title="">
                                <div className="row">
                                    <div className="col mb-4">
                                        <h2>Enter payment details.</h2>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6">
                                        <PaymentElement onChange={handlePaymentChange}/>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col text-center">
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary w-25"
                                                disabled={isBusy || !isCardComplete}
                                                onClick={() => handleWizardButtonClick('review')}>
                                                Continue
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className="btn btn-light w-25"
                                                disabled={isBusy}
                                                onClick={() => handleWizardButtonClick('choosePlan')}>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="review" title="">
                                <div className="row">
                                    <div className="col">
                                        <h2>Review your subscription.</h2>
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-3">
                                    <div className="col-12 col-md-6">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                {selectedProduct &&
                                                    <h3 className="mt-3">
                                                        {selectedProduct.productName}
                                                    </h3>
                                                }

                                                {selectedPrice &&
                                                    <h3 className="mt-3 summary">
                                                        {formatCurrency(selectedPrice.amount)}/
                                                        {selectedPrice.recurringInterval}
                                                    </h3>
                                                }

                                                <p className="m-3">{reviewMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col text-center">
                                        <div>
                                            <SubmitButton
                                                isFormReady={allowSubscribe}
                                                featureStatus={status}
                                                text="Subscribe"
                                                additionalClassName="w-25"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                className="btn btn-light w-25"
                                                disabled={isBusy}
                                                onClick={() => handleWizardButtonClick(reviewPreviousTab)}>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </form>
    );
};
