export class PageEndpoints {
    static readonly notFound = '/not-found';

    static readonly home = '/';
    static readonly publicContactUs = '/contact-us';
    static readonly publicFaqs = '/faqs';
    static readonly publicPricing = '/pricing';
    static readonly publicPrivacyPolicy = '/privacy-policy';
    static readonly publicTermsOfUse = '/terms-of-use';

    static readonly company = '/company';
    static readonly companyNew = '/add-company';

    static readonly reports = '/reports';
    static readonly reportsAdd = '/add-report';

    static readonly analysis = '/analysis';

    static readonly userSignIn = '/sign-in';
    static readonly userSignOut = '/sign-out';
    static readonly userSignUp = '/sign-up';
    static readonly userSubscription = '/get-started';

    static readonly userEmailVerification = '/verify-email';

    /* Request a new email verification code. */
    static readonly userEmailVerificationRequest = '/request-email-verification';

    /* Email was sent message. */
    static readonly userEmailVerificationStatus = '/email-verification-status';

    static readonly userAccount = '/account';
    static readonly userIntegration = '/app-integration';
    static readonly userChangePasswordConfirmation = '/change-password';
    static readonly userChangePasswordRequest = '/forgot-password';
    static readonly userChangePasswordStatus = '/forgot-password-status';
}
