import * as React from 'react';

export default function Status(
    {message}: {
        message: string | undefined | null
    }) {

    let msg;

    if (message === undefined || message === null) {
        msg = 'Loading';
    } else {
        msg = message;
    }

    msg += '...';

    return (
        <div className="loading-data">
            <small className="text-primary">
                <span>{msg}</span>
                <span className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
            </small>
        </div>
    );
}