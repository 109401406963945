import {KpiAggregate} from "./kpiAggregate";
import {KpiCategoryKeys} from "./kpiCategoryKeys";
import {KPI_KEYS_ORDER} from "./kpiKeysOrder";

export function sort(kpis: KpiAggregateList) {

    const sortedKpis: KpiAggregateList = new KpiAggregateList();

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: KpiAggregate) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}

export class KpiAggregateList extends Array<KpiAggregate> {

    static fromJson(json: any[]): KpiAggregateList {
        let obj = new KpiAggregateList();

        json.forEach(function (item: any) {
            obj.push(KpiAggregate.fromJson(item));
        });

        // Sort according to UI requirements.
        obj = sort(obj);

        return obj;
    }

    getCategoryName(categoryKey: KpiCategoryKeys): string {
        let name = '';

        this.forEach(function (kpi: KpiAggregate) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKeys): KpiAggregateList {
        const results: KpiAggregateList = new KpiAggregateList();

        this.forEach(function (kpi: KpiAggregate) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}