import * as React from "react";
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {Company} from "../../api/companies/company";
import {CompanyKpisGetParams} from "../../api/companies/companyKpisGetParams";
import {EntitlementTypes} from "../../api/entitlementTypes";
import {KpiList} from "../../api/metrics/kpiList";
import {Report} from "../../api/reports/report";
import {ReportList} from "../../api/reports/reportList";
import {useAuth} from "../../AuthProvider";
import ContentError from "../../components/ContentError";
import FormError from "../../components/FormError";
import usePostData from "../../hooks/usePostData";
import useReportDownload from "../../hooks/useReportDownload";
import {Strings} from "../../locales/strings";
import {PageStatus} from "../pageStatus";
import {ReportView} from "./ReportView";

/** Gets the current report. If we get here, we assume there is at least one report. **/
const getReport = (reportList: ReportList, reportDate: string): Report | null => {

    let selectedReport: Report | null = null;

    reportList.forEach(function (report: Report) {
        if (report.periodEndDate === reportDate) {
            selectedReport = report;
            return;
        }
    });

    return selectedReport;
};

export default function ReportContent(
    {company, reportList}: {
        company: Company | null,
        reportList: ReportList
    }) {
    const {getEntitlement} = useAuth();

    const companyId = company?.companyId || '';
    const companyName = company?.companyName || '';
    const currencyCode = company?.currencyCode || '';
    const defaultReportDate = reportList[0].periodEndDate;

    const [reportDate, setReportDate] = useState<string>(defaultReportDate);
    const {status: kpiStatus, message, submitForm, resetState, results: kpiResults} = usePostData();
    const [kpiList, setKpiList] = useState<any | null>(null);

    // Fetch KPI data.
    useEffect(() => {
        if (kpiStatus === PageStatus.IsReady) {
            const data = new CompanyKpisGetParams();
            data.companyId = companyId;
            data.periodEndDate = reportDate;

            submitForm(ApiEndpoints.companyMetricsKpisGet, data.toJson(), false, false);
        }
    }, [kpiStatus, companyId, reportDate, submitForm]);

    // Load KPI data after fetch.
    useEffect(() => {
        if (kpiStatus === PageStatus.HasSubmitted) {
            setKpiList(KpiList.fromJson(kpiResults));
        }
    }, [kpiStatus, kpiResults]);

    const {
        status: downloadStatus,
        message: downloadMessage,
        downloadReport
    } = useReportDownload(companyId, companyName, reportDate);

    const handleDownload = () => {
        downloadReport();
    };

    const handleReportDateChange = (event: any) => {
        resetState();
        setReportDate(event.target.value);
    };

    const report = getReport(reportList, reportDate);

    const isDisabled =
        kpiStatus === PageStatus.IsSubmitting ||
        downloadStatus === PageStatus.IsSubmitting;

    const isDownloadAuthorized = getEntitlement(EntitlementTypes.REPORTS_EXPORT) || false;

    return (
        <div className="content reports">
            <div className="row mb-4">
                <div className="col">
                    <h2>Select your report</h2>
                </div>
                <div className="col-auto">
                    <select
                        id="reportDate"
                        className="form-select form-select-sm"
                        disabled={isDisabled}
                        onChange={handleReportDateChange}
                    >
                        {reportList.map((e: any) =>
                            <option
                                value={e.periodEndDate}
                                key={e.periodEndDate}>
                                {e.periodEndDate} (Q{e.quarter})
                            </option>)}
                    </select>
                </div>
                <div className="col-auto">
                    {
                        isDownloadAuthorized ?
                            (
                                <Button
                                    variant="primary"
                                    size="sm"
                                    disabled={isDisabled}
                                    onClick={handleDownload}>
                                    Download
                                </Button>
                            ) : <></>
                    }

                    {!isDownloadAuthorized ?
                        (
                            <OverlayTrigger
                                placement="auto"
                                delay={{show: 0, hide: 700}}
                                overlay={(
                                    <Popover>
                                        <Popover.Body>
                                            {Strings.FEATURE_DISABLED}
                                        </Popover.Body>
                                    </Popover>
                                )}>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    disabled={isDisabled}
                                >
                                    Download
                                </Button>
                            </OverlayTrigger>
                        ) : <></>
                    }
                </div>
            </div>

            <ContentError message={message}/>
            <FormError status={downloadStatus} message={downloadMessage}/>

            <div className="row">
                <div className="col">
                    <ReportView
                        status={kpiStatus}
                        report={report}
                        kpiList={kpiList}
                        currencyCode={currencyCode}
                    />
                </div>
            </div>
        </div>
    );
}