import {Kpi} from "../../api/metrics/kpi";
import {KpiAggregate} from "../../api/metrics/kpiAggregate";
import {KpiAggregateList} from "../../api/metrics/kpiAggregateList";
import {KpiCategoryKeys} from "../../api/metrics/kpiCategoryKeys";
import {KPI_CORE_KEYS_ORDER} from "../../api/metrics/kpiCoreKeysOrder";
import {KPI_KEYS_ORDER} from "../../api/metrics/kpiKeysOrder";
import {KpiList} from "../../api/metrics/kpiList";
import {KpiPrognosis} from "../../api/metrics/kpiPrognosis";
import {KpiPrognosisList} from "../../api/metrics/kpiPrognosisList";
import {ChartData} from "../../components/charts/chartData";
import {ChartDataList} from "../../components/charts/chartDataList";
import {enumValues} from "../../utils/enumUtils";
import {round} from "../../utils/numberUtils";

export class KpiAggs {
    kpiKey: string = '';
    kpiName: string = '';
    kpiNameLong: string = '';
    kpiValue: number | null = null;
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';
    agg25Value: number | null = null;
    agg50Value: number | null = null;
    agg75Value: number | null = null;
}

export class KpiAggsList extends Array<KpiAggs> {
}

export class SeddaData {
    xDomain: [number, number] = [0, 0];
    yDomain: [number, number] = [0, 0];
    xRef: number = 0;
    yRef: number = 0;
    companyKpiValue: number | null = null;
    companyKpiPrognosisValue: number | null = null;
    benchmarkKpiValue: number | null = null;
    benchmarkKpiPrognosisValue: number | null = null;
}

export function getKpiVsAggs(
    kpiList: KpiList,
    kpiAggregateList: KpiAggregateList): Map<string, KpiAggsList> | null {

    if (kpiList === null || kpiAggregateList === null) {
        return null;
    }

    // Combine both lists by kpi key.
    const kpisByKeyTemp = new Map<string, KpiAggs>();

    kpiList.forEach(function (item: Kpi) {
        const k = new KpiAggs();
        k.kpiKey = item.kpiKey;
        k.kpiName = item.kpiName;
        k.kpiNameLong = item.kpiNameLong;
        k.kpiValue = item.kpiValue;
        k.kpiCategoryKey = item.kpiCategoryKey;
        k.kpiCategoryName = item.kpiCategoryName;

        kpisByKeyTemp.set(item.kpiKey, k);
    });

    // Update kpis with industry agg info.
    kpiAggregateList.forEach(function (item: KpiAggregate) {
        const existingItem = kpisByKeyTemp.get(item.kpiKey) || new KpiAggs();

        const aggKey = 'agg' + item.aggKey.replace('%', '') + 'Value';
        const source: any = {};
        source[aggKey] = item.aggValue;

        kpisByKeyTemp.set(item.kpiKey, Object.assign({}, existingItem, source));
    });

    // Reorder as per Ricardo.
    const kpisByKey = new Map<string, KpiAggs>();

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {

        kpisByKeyTemp.forEach((value: KpiAggs, key: string) => {
            if (kpiKey === key) {
                kpisByKey.set(key, value);
            }
        });
    });

    // Divide by KPI category key.
    const kpiCategoryKeys = enumValues(KpiCategoryKeys);
    const companyKpisByCategory = new Map<string, KpiAggsList>();

    kpiCategoryKeys.forEach(function (categoryKey: string) {
        companyKpisByCategory.set(categoryKey, new KpiAggsList());
    });

    kpisByKey.forEach((item: KpiAggs) => {
        item.kpiValue = round(item.kpiValue, 3);
        item.agg25Value = round(item.agg25Value, 3);
        item.agg50Value = round(item.agg50Value, 3);
        item.agg75Value = round(item.agg75Value, 3);

        const kal = companyKpisByCategory.get(item.kpiCategoryKey) || new KpiAggsList();
        kal.push(item);
        companyKpisByCategory.set(item.kpiCategoryKey, kal);
    });

    return companyKpisByCategory;
}

export function getPrognosisVsIndustryDict(
    companyKpis: KpiList | null,
    companyPrognoses: KpiPrognosisList | null,
    industryKpiAggs: KpiAggregateList | null,
    industryPrognoses: KpiPrognosisList | null): Map<string, ChartDataList> | null {

    if (companyKpis === null || companyPrognoses === null ||
        industryKpiAggs === null || industryPrognoses === null) {
        return null;
    }

    const prognosis = new Map<string, ChartDataList>();

    KPI_CORE_KEYS_ORDER.forEach(function (key: string) {
        prognosis.set(key, new ChartDataList());
    });

    companyKpis.forEach(function (item: Kpi) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        const cdl = prognosis.get(item.kpiKey) || new ChartDataList();
        const cd = new ChartData();
        cd.name = 'Company\nCurrent';
        cd.value = round(item.kpiValue, 3);
        cdl.push(cd);
    });

    companyPrognoses.forEach(function (item: KpiPrognosis) {
        const cdl = prognosis.get(item.kpiKey) || new ChartDataList();
        const cd = new ChartData();
        cd.name = 'Company\nPrognosis';
        cd.value = round(item.kpiPrognosisValue, 3);
        cdl.push(cd);
    });

    industryKpiAggs.forEach(function (item: KpiAggregate) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        // The agg_keys are returned in order: 25, 50, 75.
        const cdl = prognosis.get(item.kpiKey) || new ChartDataList();
        const cd = new ChartData();
        cd.name = 'Industry\n' + item.aggKey;
        cd.value = round(item.aggValue, 3);
        cdl.push(cd);
    });

    industryPrognoses.forEach(function (item: KpiPrognosis) {
        if (KPI_CORE_KEYS_ORDER.indexOf(item.kpiKey) === -1) {
            return;
        }

        const cdl = prognosis.get(item.kpiKey) || new ChartDataList();
        const cd = new ChartData();
        cd.name = 'Ind Avg\nPrognosis';
        cd.value = round(item.kpiPrognosisValue, 3);
        cdl.push(cd);
    });

    return prognosis;
}

export function getSeddaDict(
    companyKpis: KpiList | null,
    companyPrognoses: KpiPrognosisList | null,
    industryKpiAggs: KpiAggregateList | null,
    industryPrognoses: KpiPrognosisList | null): Map<string, SeddaData> | null {

    if (companyKpis === null || companyPrognoses === null ||
        industryKpiAggs === null || industryPrognoses === null) {
        return null;
    }

    const seddaResult = new Map<string, SeddaData>();

    // Get company current values.
    companyKpis.forEach(function (item: Kpi) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const seddaData = new SeddaData();
            seddaData.companyKpiValue = item.kpiValue;
            seddaResult.set(key, seddaData);
        }
    });

    // Get company predicted values.
    companyPrognoses.forEach(function (item: KpiPrognosis) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const seddaData = seddaResult.get(key) || new SeddaData();
            seddaData.companyKpiPrognosisValue = item.kpiPrognosisValue;
        }
    });

    // Get industry current values 50th percentile.
    industryKpiAggs.forEach(function (item: KpiAggregate) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1 && item.aggKey === '50%') {
            const seddaData = seddaResult.get(key) || new SeddaData();
            seddaData.benchmarkKpiValue = item.aggValue;
        }
    });

    // Get industry predicted values.
    industryPrognoses.forEach(function (item: KpiPrognosis) {
        const key = item.kpiKey;

        if (KPI_CORE_KEYS_ORDER.indexOf(key) > -1) {
            const seddaData = seddaResult.get(key) || new SeddaData();
            seddaData.benchmarkKpiPrognosisValue = item.kpiPrognosisValue;
        }
    });

    // Determine the domain/range for the plot.
    seddaResult.forEach(function (item: SeddaData) {
        const xCo = item.companyKpiValue || 0;
        const xInd = item.benchmarkKpiValue || 0;
        const yCo = item.companyKpiPrognosisValue || 0;
        const yInd = item.benchmarkKpiPrognosisValue || 0;

        const xMin = Math.min(xInd, xCo, 0);
        const xMax = Math.max(xInd, xCo, 0);
        const yMin = Math.min(yInd, yCo, 0);
        const yMax = Math.max(yInd, yCo, 0);

        const minVal = Math.min(xMin, yMin, 0);
        const maxVal = Math.max(xMax, yMax);
        const minInd = Math.min(xInd, yInd);

        let xRange: [number, number];
        let yRange: [number, number];

        if (minVal < 0) {
            xRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
        } else {
            xRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
        }

        item.xDomain = xRange;
        item.yDomain = yRange;
        item.xRef = minInd;
        item.yRef = minInd;
    });

    return seddaResult;
}