import * as React from 'react';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

import Colors from '../../utils/colors';
import {KpiAggsList} from "./chartUtils";

const customLabel = ({x, y, payload}: any) => {

    payload.value = payload.value.replaceAll(' ', '\n');
    payload.value = payload.value.replace('Turnover', 'Turn');

    const rows = payload.value.split('\n');

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill={Colors.textMedium} fontSize={12}>
                <tspan textAnchor="middle" x="0">{rows[0]}</tspan>
                <tspan textAnchor="middle" x="0" dy="15">{rows[1]}</tspan>
                <tspan textAnchor="middle" x="0" dy="15">{rows[2]}</tspan>
            </text>
        </g>
    );
};

export function KpiAggChart(
    {data}: {
        data: KpiAggsList
    }) {

    return (
        <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
                width={400}
                height={400}
                data={data}
                margin={{top: 20, right: 20, bottom: 20, left: -10}}
            >
                <CartesianGrid stroke={Colors.lightLine}/>
                <XAxis
                    dataKey="kpiName"
                    interval={0}
                    padding={{left: 30, right: 30}}
                    tick={customLabel}
                />
                <YAxis
                    stroke={Colors.textMedium}
                    tick={{fontSize: 12}}
                />
                <Tooltip/>
                <Legend
                    align="center"
                    iconSize={10}
                    verticalAlign="bottom"
                    wrapperStyle={{
                        paddingTop: "30px"
                    }}
                />

                {
                    data && data.map((item: any) => {
                        return (<ReferenceLine key={item.kpiKey} stroke={Colors.secondary} segment={[{
                            x: item.kpiName,
                            y: item.agg25Value
                        }, {x: item.kpiName, y: item.agg75Value}]}/>);
                    })
                }

                <Scatter
                    dataKey="agg25Value"
                    name="25th Percentile"
                    fill={Colors.secondary}
                    shape="square"
                    legendType="square"/>
                <Scatter
                    dataKey="agg50Value"
                    name="50th Percentile"
                    fill={Colors.secondary}/>
                <Scatter
                    dataKey="agg75Value"
                    name="75th Percentile"
                    fill={Colors.secondary}
                    shape="triangle"
                    legendType="triangle"/>

                <Line dataKey="kpiValue" name="Company" stroke={Colors.primary} strokeWidth={2}/>
            </ComposedChart>
        </ResponsiveContainer>
    );
}