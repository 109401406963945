export class IndustryKpiPrognosesGetParams {
    sectorKey: string = '';
    periodEndDate: string = '';
    kpiCategoryKeys: string[] = [];

    toJson() {
        return {
            "sector_key": this.sectorKey,
            "period_end_date": this.periodEndDate,
            "kpi_category_keys": this.kpiCategoryKeys
        };
    }
}