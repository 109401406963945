import * as React from "react";
import {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {useLocation} from "react-router-dom";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {AppIntegrationToken} from "../../api/user/appIntegrationToken";
import usePostData from "../../hooks/usePostData";
import {PageTitles} from "../../locales/pageTitles";
import {PageStatus} from "../pageStatus";
import AppIntegrationHeader from "./AppIntegrationHeader";

export function AppIntegration() {
    useEffect(() => {
        document.title = PageTitles.userAppIntegration;
    }, []);

    const [code, setCode] = useState<string | null>(null);
    const [realmId, setRealmId] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);

    // Since auth URL redirects, the status is reset to IS_READY.
    const {
        status: authUrlStatus,
        message: authUrlMessage,
        submitForm: submitAuthUrl,
        results: authUrlResults
    } = usePostData();

    // Get access token.
    const {
        status: tokenStatus,
        message: tokenMessage,
        submitForm: submitToken,
        results: tokenResults
    } = usePostData();

    // Get chart of accounts.
    const {
        status: accountsStatus,
        message: accountsMessage,
        submitForm: submitAccounts,
        results: accountsResults
    } = usePostData();

    // TODO: Check error response/status of above.
    console.log('authUrlStatus: ' + authUrlStatus);
    console.log('authUrlMessage: ' + authUrlMessage);
    console.log('tokenStatus: ' + tokenStatus);
    console.log('tokenMessage: ' + tokenMessage);

    // const [authUrl, setAuthUrl] = useState('');

    // TODO: Check if user is already authorized.
    // TODO: If not, show 'connect' button.
    // TODO: Check for when user selects to not authorize. Will get invalid scope error.

    // TODO: Allow user to revoke access.

    // When auth URL returns, check for code and realm ID in URL.
    const search = useLocation().search;
    const newCode = new URLSearchParams(search).get('code');
    const newRealmId = new URLSearchParams(search).get('realmId');

    // When authorization URL is received.
    useEffect(() => {
        if (authUrlResults !== null) {
            // TODO: Not sure I need to save this. Need some way to stop this. Check status.
            // Redirect to URL to get code and realm ID.
            window.location.href = authUrlResults[0].authorization_url;
        }
    }, [authUrlResults]);

    // When code and realm are received.
    useEffect(() => {
        if (code === null || realmId === null) {
            setCode(newCode);
            setRealmId(newRealmId);
        }
    }, [code, realmId, setCode, setRealmId]);

    // If code and realm ID exist, get access token.
    useEffect(() => {
        if (tokenStatus === PageStatus.HasSubmitted && tokenResults !== null && token === null) {
            const token = AppIntegrationToken.fromJson(tokenResults[0]);
            setToken(token.accessToken);
        }
    }, [tokenStatus, tokenResults, token, setToken]);

    // Get chart of account results.
    useEffect(() => {
        if (accountsStatus === PageStatus.HasSubmitted) {
            console.log('accounts!');
        }
    }, [accountsStatus]);

    const handleAuthorize = () => {
        submitAuthUrl(ApiEndpoints.userIntegrationAuthUrl, {});
    };

    const handleAccessToken = () => {
        const data = {
            "code": code,
            "realm_id": realmId
        };

        submitToken(ApiEndpoints.userIntegrationAccessToken, data);
    };

    const handleGetBalanceSheet = () => {
        const data = {
            "access_token": token,
            "realm_id": realmId
        };

        submitToken(ApiEndpoints.appIntegrationBalanceSheet, data);
    };

    return (
        <div className="p-4 account-settings" id="accountRoot">
            <div className="row justify-content-center mb-4">
                <div className="col-12 col-xl-8 col-xxl-6">
                    <AppIntegrationHeader/>

                    <Button
                        variant="primary"
                        size="sm"
                        // disabled={downloadStatus === PageStatus.IsSubmitting}
                        onClick={handleAuthorize}>
                        Connect to Quickbooks Online
                    </Button>

                    <div>&nbsp;</div>

                    <Button
                        variant="primary"
                        size="sm"
                        // disabled={downloadStatus === PageStatus.IsSubmitting}
                        onClick={handleAccessToken}>
                        Get access token
                    </Button>

                    <div>&nbsp;</div>

                    <Button
                        variant="primary"
                        size="sm"
                        // disabled={downloadStatus === PageStatus.IsSubmitting}
                        onClick={handleGetBalanceSheet}>
                        Get balance sheet
                    </Button>
                </div>
            </div>
        </div>
    );
}
