import {KpiPrognosis} from "./kpiPrognosis";
import {KpiPrognosisList} from "./kpiPrognosisList";

export class KpiPrognosisMap extends Map<string, KpiPrognosis> {

    static fromList(kpiPrognosisList: KpiPrognosisList | null): KpiPrognosisMap | null {

        if (kpiPrognosisList === null) {
            return null;
        }

        const results = new KpiPrognosisMap();

        kpiPrognosisList.forEach(function (item: KpiPrognosis) {
            results.set(item.kpiKey, item);
        });

        return results;
    }
}