import * as React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {KpiCategoryKeys} from "../../api/metrics/kpiCategoryKeys";
import {KpiList} from "../../api/metrics/kpiList";
import {Report} from "../../api/reports/report";
import {ReportEntry} from "../../api/reports/reportEntry";
import Status from "../../components/Status";
import {PageStatus} from "../pageStatus";
import KpiGroup from "./KpiGroup";
import StatementLine from "./StatementLine";

export function ReportView(
    {status, report, kpiList, currencyCode}: {
        status: PageStatus,
        report: Report | null,
        kpiList: KpiList | null,
        currencyCode: string
    }) {

    let s1 = null;
    let s2 = null;
    let s3 = null;
    const kpis: KpiList | null = kpiList;

    if (report != null) {
        s1 = report.getBalanceSheetStatement();
        s2 = report.getCashflowStatement();
        s3 = report.getIncomeStatement();
    }

    return (
        <div>
            <Tabs
                defaultActiveKey="statements"
                id="analysis-reports-tab"
            >
                <Tab eventKey="statements" title="Statements">
                    {
                        status === PageStatus.IsSubmitting ?
                            (<Status message="Loading reports"/>)
                            : (<></>)
                    }

                    {
                        s1 != null &&
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header bg-transparent">
                                        <h3 className="card-title">{s1.statementName}</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Assets</h4>
                                                <table className="table">
                                                    <tbody>
                                                    {
                                                        s1.getAssets().map((a: ReportEntry) =>
                                                            <StatementLine
                                                                key={a.accountKey}
                                                                accountKey={a.accountKey}
                                                                accountName={a.accountName}
                                                                amount={a.amount}
                                                                currency={currencyCode}
                                                            />)
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                    <StatementLine
                                                        key="total_assets"
                                                        accountKey="total_assets"
                                                        accountName="Total Assets"
                                                        amount={s1.getAssetsTotal()}
                                                        currency={currencyCode}
                                                    />
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="col-md-6 mt-sm-3 mt-md-0">
                                                <h4>Liabilities + Equity</h4>
                                                <table className="table">
                                                    <tbody>
                                                    {
                                                        s1.getLiabilities().map((a: ReportEntry) =>
                                                            <StatementLine
                                                                key={a.accountKey}
                                                                accountKey={a.accountKey}
                                                                accountName={a.accountName}
                                                                amount={a.amount}
                                                                currency={currencyCode}
                                                            />)
                                                    }
                                                    {
                                                        s1.getEquity().map((a: ReportEntry) =>
                                                            <StatementLine
                                                                key={a.accountKey}
                                                                accountKey={a.accountKey}
                                                                accountName={a.accountName}
                                                                amount={a.amount}
                                                                currency={currencyCode}
                                                            />)
                                                    }
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    </tbody>
                                                    <tfoot>
                                                    <StatementLine
                                                        key="total_liabilities_equity"
                                                        accountKey="total_liabilities_equity"
                                                        accountName="Total Liabilities + Equity"
                                                        amount={s1.getLiabilitiesTotal() + s1.getEquityTotal()}
                                                        currency={currencyCode}
                                                    />
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        s2 != null && s3 != null &&
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header bg-transparent">
                                        <h3 className="card-title">{s2.statementName}</h3>
                                    </div>
                                    <div className="card-body">
                                        <table className="table">
                                            <tbody>
                                            {
                                                s2.getCashflows().map((a: any) =>
                                                    <StatementLine
                                                        key={a.accountKey}
                                                        accountKey={a.accountKey}
                                                        accountName={"Quarterly " + a.accountName}
                                                        amount={a.amount}
                                                        currency={currencyCode}
                                                    />)
                                            }
                                            </tbody>
                                            <tfoot>
                                            <StatementLine
                                                key="net_cashflows"
                                                accountKey="net_cashflows"
                                                accountName="Quarterly Net Cashflows"
                                                amount={s2.getCashflowsTotal()}
                                                currency={currencyCode}
                                            />
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card mt-4 mt-md-0">
                                    <div className="card-header bg-transparent">
                                        <h3 className="card-title">{s3.statementName}</h3>
                                    </div>
                                    <div className="card-body">
                                        <table className="table">
                                            <tbody>
                                            {
                                                s3.getRevenues().map((a: any) =>
                                                    <StatementLine
                                                        key={a.accountKey}
                                                        accountKey={a.accountKey}
                                                        accountName={a.accountName}
                                                        amount={a.amount}
                                                        currency={currencyCode}
                                                    />)
                                            }
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Tab>

                <Tab eventKey="kpis" title="KPIs">
                    {
                        status === PageStatus.IsSubmitting ?
                            (<Status message="Loading reports"/>)
                            : (<></>)
                    }

                    <div className="row">
                        <div className="col-lg-6">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.LIQUIDITY)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.LIQUIDITY)}
                                />
                            }
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.DAYS)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.DAYS)}
                                />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.TURNOVER)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.TURNOVER)}
                                />
                            }
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.RETURN)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.RETURN)}
                                />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.PROFITABILITY)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.PROFITABILITY)}
                                />
                            }
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.LEVERAGE)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.LEVERAGE)}
                                />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.CASHFLOW)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.CASHFLOW)}
                                />
                            }
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.LYCA)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.LYCA)}
                                />
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.IAICOC)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.IAICOC)}
                                />
                            }
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {
                                kpis !== null &&
                                <KpiGroup
                                    categoryName={kpis.getCategoryName(KpiCategoryKeys.ROA2BOND)}
                                    kpis={kpis.getKpisByCategory(KpiCategoryKeys.ROA2BOND)}
                                />
                            }
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}