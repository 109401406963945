import {Kpi} from "./kpi";
import {KpiCategoryKeys} from "./kpiCategoryKeys";
import {KPI_KEYS_ORDER} from "./kpiKeysOrder";

export function sort(kpis: KpiList) {

    const sortedKpis = new KpiList();

    KPI_KEYS_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}

export class KpiList extends Array<Kpi> {

    static fromJson(json: any[]): KpiList {
        let obj = new KpiList();

        json.forEach(function (item: any) {
            obj.push(Kpi.fromJson(item));
        });

        // Sort according to UI requirements.
        obj = sort(obj);

        return obj;
    }

    getCategoryName(categoryKey: KpiCategoryKeys): string {
        let name = '';

        this.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKeys): KpiList {
        const results: KpiList = new KpiList();

        this.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}