import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {ApiEndpoints} from "../../api/apiEndpoints";
import {CompanyBenchmarksGetParams} from "../../api/companies/companyBenchmarksGetParams";
import {CompanyKpiPrognosesGetParams} from "../../api/companies/companyKpiPrognosesGetParams";
import {CompanyKpisGetParams} from "../../api/companies/companyKpisGetParams";
import {IndustryBenchmarksGetParams} from "../../api/industries/industryBenchmarksGetParams";
import {IndustryKpiAggregatesGetParams} from "../../api/industries/industryKpiAggregatesGetParams";
import {IndustryKpiPrognosesGetParams} from "../../api/industries/industryKpiPrognosesGetParams";
import {BenchmarkList} from "../../api/metrics/benchmarkList";
import {KpiAggregateList} from "../../api/metrics/kpiAggregateList";
import {KpiInfoList} from "../../api/metrics/kpiInfoList";
import {KpiKeys} from "../../api/metrics/kpiKeys";
import {KpiList} from "../../api/metrics/kpiList";
import {KpiPrognosisList} from "../../api/metrics/kpiPrognosisList";
import {KpiPrognosisMap} from "../../api/metrics/kpiPrognosisMap";
import {useAuth} from "../../AuthProvider";
import {ChartDataList} from "../../components/charts/chartDataList";
import useMultiPostData from "../../hooks/useMultiPostData";
import {getValueByKey} from "../../utils/objectUtils";
import {PageStatus} from "../pageStatus";
import BalanceSheetIndustryChart from "./BalanceSheetIndustryChart";
import CcpChart from "./CcpChart";
import {
    getKpiVsAggs,
    getPrognosisVsIndustryDict,
    getSeddaDict,
    KpiAggsList, SeddaData
} from "./chartUtils";
import KpiAggCard from "./KpiAggCard";
import PrognosisIndustryChart from "./PrognosisIndustryChart";
import SeddaChart from "./SeddaChart";

// TODO: Inefficient to get KPI info on every page render. Only need once per app lifetime.
const PROGNOSES_URLS: string[] = [
    ApiEndpoints.companyMetricsKpisPrognosesGet,
    ApiEndpoints.industryMetricsKpisPrognosesGet,
    ApiEndpoints.kpisList
];

const KPI_URLS: string[] = [
    ApiEndpoints.companyMetricsKpisGet,
    ApiEndpoints.industryMetricsKpisAggregatesGet
];

const BENCHMARK_URLS: string[] = [
    ApiEndpoints.companyMetricsBenchmarksGet,
    ApiEndpoints.industryMetricsBenchmarksGet
];

export default function AnalysisCharts(
    {companyId, sectorKey, reportDate}: {
        companyId: string,
        sectorKey: string,
        reportDate: string
    }) {

    const {getUser} = useAuth();
    const user = getUser();

    const authorizedPrognosisCategoryKeys = useMemo(
        () => user?.entitlements.getAuthorizedPrognosisCategoryKeys() || [], [user]
    );

    // IMPORTANT: We can only make a few asynchronous API calls at once.
    const {
        status: prognosisStatus,
        submitPost: submitPrognoses,
        results: prognosisResults
    } = useMultiPostData(PROGNOSES_URLS.length);

    const {
        status: kpiStatus,
        submitPost: submitKpis,
        results: kpiResults
    } = useMultiPostData(KPI_URLS.length);

    const {
        status: benchmarkStatus,
        submitPost: submitBenchmarks,
        results: benchmarkResults
    } = useMultiPostData(BENCHMARK_URLS.length);

    const [kpiInfos, setKpiInfos] = useState<KpiInfoList | null>(null);

    const [companyPrognoses, setCompanyPrognoses] = useState<KpiPrognosisList | null>(null);
    const [industryPrognoses, setIndustryPrognoses] = useState<KpiPrognosisList | null>(null);

    const [companyKpis, setCompanyKpis] = useState<KpiList | null>(null);
    const [industryKpiAggs, setIndustryKpiAggs] = useState<KpiAggregateList | null>(null);

    const [companyBenchmarks, setCompanyBenchmarks] = useState<BenchmarkList | null>(null);
    const [industryBenchmarks, setIndustryBenchmarks] = useState<BenchmarkList | null>(null);

    // Data in correct "shape" for charts.
    const [companyCcp, setCompanyCcp] = useState<KpiPrognosisMap | null>(null);
    const [kpisVsIndustry, setKpisVsIndustry] = useState<Map<string, KpiAggsList> | null>(null);
    const [prognosisVsIndustry, setPrognosisVsIndustry] = useState<Map<string, ChartDataList> | null>(null);
    const [sedda, setSedda] = useState<Map<string, SeddaData> | null>(null);
    const [companyAssets, setCompanyAssets] = useState<BenchmarkList | null>(null);
    const [companyLiabilities, setCompanyLiabilities] = useState<BenchmarkList | null>(null);
    const [industryAssets, setIndustryAssets] = useState<BenchmarkList | null>(null);
    const [industryLiabilities, setIndustryLiabilities] = useState<BenchmarkList | null>(null);

    // Only submit 1 "batch" of URLs at a time. Keeps from overloading API.
    const [batchNum, setBatchNum] = useState(0);

    // Fetch company and industry prognosis data.
    useEffect(() => {
        if (batchNum === 0 && prognosisStatus === PageStatus.IsReady) {

            const companyData = new CompanyKpiPrognosesGetParams();
            companyData.companyId = companyId;
            companyData.periodEndDate = reportDate;
            companyData.kpiCategoryKeys = authorizedPrognosisCategoryKeys;

            const industryData = new IndustryKpiPrognosesGetParams();
            industryData.sectorKey = sectorKey;
            industryData.periodEndDate = reportDate;
            industryData.kpiCategoryKeys = authorizedPrognosisCategoryKeys;

            submitPrognoses(PROGNOSES_URLS, [companyData.toJson(), industryData.toJson(), {}]);
        }
    }, [batchNum, prognosisStatus, companyId, sectorKey, reportDate, authorizedPrognosisCategoryKeys, submitPrognoses]);

    // Fetch KPI and aggregate data.
    useEffect(() => {
        if (batchNum === 1 && kpiStatus === PageStatus.IsReady) {
            const kpiData = new CompanyKpisGetParams();
            kpiData.companyId = companyId;
            kpiData.periodEndDate = reportDate;

            const aggData = new IndustryKpiAggregatesGetParams();
            aggData.sectorKey = sectorKey;
            aggData.periodEndDate = reportDate;
            aggData.aggKeys = ["25%", "50%", "75%"];

            submitKpis(KPI_URLS, [kpiData.toJson(), aggData.toJson()]);
        }
    }, [batchNum, kpiStatus, companyId, sectorKey, reportDate, submitKpis]);

    // Fetch benchmark data.
    useEffect(() => {
        if (batchNum === 2 && benchmarkStatus === PageStatus.IsReady) {
            const companyData = new CompanyBenchmarksGetParams();
            companyData.companyId = companyId;
            companyData.periodEndDate = reportDate;

            const industryData = new IndustryBenchmarksGetParams();
            industryData.sectorKey = sectorKey;
            industryData.periodEndDate = reportDate;

            submitBenchmarks(BENCHMARK_URLS, [companyData.toJson(), industryData.toJson()]);
        }
    }, [batchNum, benchmarkStatus, companyId, sectorKey, reportDate, submitBenchmarks]);

    // Load prognoses data after fetching.
    useEffect(() => {
        if (prognosisStatus === PageStatus.HasSubmitted &&
            prognosisResults[0].length > 0 && companyPrognoses === null &&
            prognosisResults[1].length > 0 && industryPrognoses === null &&
            prognosisResults[2].length > 0 && kpiInfos === null) {

            const companyPrognosisList = KpiPrognosisList.fromJson(prognosisResults[0]);
            setCompanyPrognoses(companyPrognosisList);

            const industryPrognosisList = KpiPrognosisList.fromJson(prognosisResults[1]);
            setIndustryPrognoses(industryPrognosisList);

            setCompanyCcp(KpiPrognosisMap.fromList(companyPrognosisList));

            const kpiInfoList = KpiInfoList.fromJson(prognosisResults[2]);
            setKpiInfos(kpiInfoList);

            setBatchNum(1);
        } else if (prognosisStatus === PageStatus.HasError) {
            setBatchNum(1);
        }
    }, [prognosisStatus, prognosisResults, companyPrognoses, industryPrognoses, kpiInfos, setBatchNum]);

    // Load KPI and aggregate data after fetching.
    useEffect(() => {
        if (kpiStatus === PageStatus.HasSubmitted &&
            kpiResults[0].length > 0 && companyKpis === null &&
            kpiResults[1].length > 0 && industryKpiAggs === null) {

            const kpiList = KpiList.fromJson(kpiResults[0]);
            setCompanyKpis(kpiList);

            const kpiAggList = KpiAggregateList.fromJson(kpiResults[1]);
            setIndustryKpiAggs(kpiAggList);

            const kpiVsAggsList = getKpiVsAggs(kpiList, kpiAggList);
            setKpisVsIndustry(kpiVsAggsList);

            setBatchNum(2);
        } else if (kpiStatus === PageStatus.HasError) {
            setBatchNum(2);
        }
    }, [kpiStatus, kpiResults, companyKpis, industryKpiAggs, setBatchNum]);

    // Load benchmark data after fetching.
    useEffect(() => {
        if (benchmarkStatus === PageStatus.HasSubmitted &&
            benchmarkResults[0].length > 0 && companyBenchmarks === null &&
            benchmarkResults[1].length > 0 && industryBenchmarks === null) {

            const companyBenchmarkList = BenchmarkList.fromJson(benchmarkResults[0]);
            setCompanyBenchmarks(companyBenchmarkList);

            const industryBenchmarkList = BenchmarkList.fromJson(benchmarkResults[1]);
            setIndustryBenchmarks(industryBenchmarkList);

            const companyAssets = companyBenchmarkList.getAssets();
            setCompanyAssets(companyAssets);

            const companyLiabilities = companyBenchmarkList.getLiabilitiesAndEquity();
            setCompanyLiabilities(companyLiabilities);

            const industryAssets = industryBenchmarkList.getAssets();
            setIndustryAssets(industryAssets);

            const industryLiabilities = industryBenchmarkList.getLiabilitiesAndEquity();
            setIndustryLiabilities(industryLiabilities);

            setBatchNum(3);
        } else if (benchmarkStatus === PageStatus.HasError) {
            setBatchNum(3);
        }
    }, [benchmarkStatus, benchmarkResults, companyBenchmarks, industryBenchmarks, setBatchNum]);

    // Load remaining charts after all data is ready.
    useEffect(() => {
        if (companyKpis !== null && companyPrognoses !== null &&
            industryKpiAggs !== null && industryPrognoses !== null) {

            const prognosisVsIndustryDict = getPrognosisVsIndustryDict(
                companyKpis,
                companyPrognoses,
                industryKpiAggs,
                industryPrognoses);
            setPrognosisVsIndustry(prognosisVsIndustryDict);


            const seddaDict = getSeddaDict(
                companyKpis,
                companyPrognoses,
                industryKpiAggs,
                industryPrognoses);
            setSedda(seddaDict);
        }
    }, [companyKpis, companyPrognoses, industryKpiAggs, industryPrognoses, setPrognosisVsIndustry, setSedda]);

    const isAuth = useCallback((kpiKey: string) => {
        if (kpiInfos === null) {
            return false;
        }

        const categoryKey = kpiInfos.getKpiCategoryKey(kpiKey);

        return authorizedPrognosisCategoryKeys.includes(categoryKey);
    }, [kpiInfos, authorizedPrognosisCategoryKeys]);

    return (
        <div>
            <Tabs
                defaultActiveKey="ccp"
                id="analysis-reports-tab"
            >
                <Tab eventKey="ccp" title="Competitive Prognosis">
                    <div className="row">
                        <div className="col">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <span className="fw-bold">and</span> will
                                be at producing profits?
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.NET_MARGIN}/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OPERATING_MARGIN}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <span className="fw-bold">and</span> will
                                be at managing assets to turn profits?
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROA}/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROE}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <br/><span className="fw-bold">and</span> will be at
                                improving liquidity?
                            </div>
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.CASH_RATIO}/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <br/><span className="fw-bold">and</span> will be at
                                producing higher-quality cashflows?
                            </div>
                            <CcpChart
                                status={prognosisStatus}
                                data={companyCcp}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OCG}/>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="prognosis" title="Prognosis vs Industry">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.NET_MARGIN}
                                isAuth={isAuth(KpiKeys.NET_MARGIN)}
                            />
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OPERATING_MARGIN}
                                isAuth={isAuth(KpiKeys.OPERATING_MARGIN)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROA}
                                isAuth={isAuth(KpiKeys.ROA)}
                            />
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROE}
                                isAuth={isAuth(KpiKeys.ROE)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.CASH_RATIO}
                                isAuth={isAuth(KpiKeys.CASH_RATIO)}
                            />
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                status={kpiStatus}
                                data={prognosisVsIndustry}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OCG}
                                isAuth={isAuth(KpiKeys.OCG)}
                            />
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="kpis" title="KPIs vs Industry">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggCard
                                title="Liquidity Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'liquidity')}
                            ></KpiAggCard>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggCard
                                title="Days Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'days')}
                            ></KpiAggCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggCard
                                title="Turnover Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'turnover')}
                            ></KpiAggCard>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggCard
                                title="Return Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'return')}
                            ></KpiAggCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggCard
                                title="Profitability Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'profitability')}
                            ></KpiAggCard>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggCard
                                title="Leverage Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'leverage')}
                            ></KpiAggCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggCard
                                title="Quality of Cashflow Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'cashflow')}
                            ></KpiAggCard>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggCard
                                title="Liabilities to Yield Curve Ratios"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'lyca')}
                            ></KpiAggCard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggCard
                                title="Inflation Adjusted Inventory Carry-on Cost"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'iaicoc')}
                            ></KpiAggCard>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggCard
                                title="ROA-to-Bond Ratio"
                                status={kpiStatus}
                                data={getValueByKey(kpisVsIndustry, 'roa2bond')}
                            ></KpiAggCard>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="sedda" title="SEDDA">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.NET_MARGIN)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.NET_MARGIN}
                                isAuth={isAuth(KpiKeys.NET_MARGIN)}
                            />
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.OPERATING_MARGIN)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OPERATING_MARGIN}
                                isAuth={isAuth(KpiKeys.OPERATING_MARGIN)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.ROA)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROA}
                                isAuth={isAuth(KpiKeys.ROA)}
                            />
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.ROE)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.ROE}
                                isAuth={isAuth(KpiKeys.ROE)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.CASH_RATIO)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.CASH_RATIO}
                                isAuth={isAuth(KpiKeys.CASH_RATIO)}
                            />
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                status={kpiStatus}
                                data={getValueByKey(sedda, KpiKeys.OCG)}
                                kpiInfoList={kpiInfos}
                                kpiKey={KpiKeys.OCG}
                                isAuth={isAuth(KpiKeys.OCG)}
                            />
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="balanceSheet" title="Balance Sheet vs Industry">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <BalanceSheetIndustryChart
                                status={benchmarkStatus}
                                title="Company Assets"
                                data={companyAssets}/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <BalanceSheetIndustryChart
                                status={benchmarkStatus}
                                title="Industry Assets"
                                data={industryAssets}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <BalanceSheetIndustryChart
                                status={benchmarkStatus}
                                title="Company Liabilities + Equity"
                                data={companyLiabilities}/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <BalanceSheetIndustryChart
                                status={benchmarkStatus}
                                title="Industry Liabilities + Equity"
                                data={industryLiabilities}/>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}