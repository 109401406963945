import {AccountCategoryKeys} from "../reports/accountCategoryKeys";
import {Benchmark} from "./benchmark";
import {BENCHMARK_KEYS_ORDER} from "./benchmarkKeysOrder";

export function sort(benchmarks: BenchmarkList) {

    const sorted = new BenchmarkList();

    BENCHMARK_KEYS_ORDER.forEach(function (benchmarkKey: string) {
        benchmarks.forEach(function (benchmark: Benchmark) {
            if (benchmark.benchmarkKey === benchmarkKey) {
                sorted.push(benchmark);
                return;
            }
        });
    });

    return sorted;
}

export class BenchmarkList extends Array<Benchmark> {

    static fromJson(json: any[]): BenchmarkList {
        let obj = new BenchmarkList();

        json.forEach(function (item: any) {
            obj.push(Benchmark.fromJson(item));
        });
        obj = sort(obj);

        return obj;
    }

    getAssets() {
        const benchmarkList: BenchmarkList = new BenchmarkList();

        this.forEach(function (item: Benchmark) {
            if (item.accountCategoryKey === AccountCategoryKeys.ASSETS) {
                benchmarkList.push(item);
            }
        });

        return benchmarkList;
    }

    getLiabilitiesAndEquity() {
        const benchmarkList: BenchmarkList = new BenchmarkList();

        this.forEach(function (item: Benchmark) {
            if (item.accountCategoryKey === AccountCategoryKeys.LIABILITIES ||
                item.accountCategoryKey === AccountCategoryKeys.EQUITY) {
                benchmarkList.push(item);
            }
        });

        return benchmarkList;
    }
}