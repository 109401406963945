import React, {useCallback, useContext, useMemo, useState} from 'react';
import {User} from "./api/user/user";
import {AuthContext} from './AuthContext';
import {useLocalStorage} from './utils/useLocalStorage';

const JWT_KEY = 'jwt';
const JWT_EXPIRES_KEY = 'jwtExpires';
const USER_KEY = 'user';
const COMPANY_LIST_KEY = 'companies';
const COMPANY_ID_KEY = 'companyId';

const AuthProvider = ({children}: any) => {

    const {getItem, setItem, removeItem} = useLocalStorage();
    const [currentUser, setCurrentUser] = useState<User | null>(User.fromJson(JSON.parse(getItem(USER_KEY) || '{}')));

    const isAuth = useCallback(() => {
        const token = getItem(JWT_KEY);
        const tokenExpirationString = getItem(JWT_EXPIRES_KEY);

        if (token === null) {
            return false;
        }

        if (tokenExpirationString === null) {
            return false;
        }

        const nowDate = Date.now();
        const tokenExpirationDate = Date.parse(tokenExpirationString);

        // Is authenticated.
        return nowDate < tokenExpirationDate;

    }, [getItem]);

    const setUser = useCallback((user: User | null) => {

        if (user === null) {
            setItem(USER_KEY, JSON.stringify({}));
        } else {
            setItem(USER_KEY, JSON.stringify(user.toJson()));
        }

        setCurrentUser(user);
    }, [setItem, setCurrentUser]);

    const getEntitlement = useCallback((entitlement_key: string) => {
        const user = currentUser;

        if (user === null) {
            return null;
        }

        if (user.entitlements.length === 0) {
            return null;
        }

        const entitlementList = user.entitlements;

        for (let i = 0; i < entitlementList.length; i++) {
            const entitlement = entitlementList[i];
            const key = entitlement.entitlementKey;

            if (key === entitlement_key) {
                return entitlement.entitlementValue;
            }
        }

        return null;
    }, [currentUser]);

    const getUser = useCallback(() => {
        if (!isAuth()) {
            return null;
        }

        return currentUser;
    }, [isAuth, currentUser]);

    const getToken = useCallback(() => {
        return getItem(JWT_KEY) ?? '';
    }, [getItem]);

    const signIn = useCallback((jwtToken: string, jwtExpiresIn: number) => {

        function addSeconds(date: Date, seconds: number) {
            date.setSeconds(date.getSeconds() + seconds);
            return date;
        }

        const expirationDate = addSeconds(new Date(), jwtExpiresIn);

        setItem(JWT_KEY, jwtToken);
        setItem(JWT_EXPIRES_KEY, expirationDate.toString());

    }, [setItem]);

    const signOut = useCallback(() => {
        removeItem(JWT_KEY);
        removeItem(JWT_EXPIRES_KEY);
        removeItem(COMPANY_LIST_KEY);
        removeItem(COMPANY_ID_KEY);
        removeItem(USER_KEY);
    }, [removeItem]);

    const authCtx = useMemo(() => ({
        isAuth: isAuth,
        signIn: signIn,
        signOut: signOut,
        getToken: getToken,
        setUser: setUser,
        getUser: getUser,
        getEntitlement: getEntitlement
    }), [isAuth, signIn, signOut, getToken, setUser, getUser, getEntitlement]);

    return (
        <AuthContext.Provider value={authCtx}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    return useContext(AuthContext);
};

export {AuthProvider, useAuth};