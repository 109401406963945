import {isEmpty} from "../../utils/pageUtils";
import {Industry} from "./industry";

export class IndustryList extends Array<Industry> {
    sectors: any = {};
    sectorNames: any = {};

    static fromJson(json: any[]): IndustryList {
        const obj = new IndustryList();

        json.forEach(function (item: any) {
            obj.push(Industry.fromJson(item));
        });

        // Get all subsectors by their parent sector.
        const sectors: any = {};
        const sectorNames: any = {};

        obj.forEach(function (item: Industry) {
            const sectorKey = item.sectorKey;
            const subsectorKey = item.subsectorKey;

            if (!(sectorKey in sectorNames)) {
                sectorNames[sectorKey] = item.sectorName;
            }

            if (!(subsectorKey in sectorNames)) {
                sectorNames[subsectorKey] = item.subsectorName;
            }

            if (!(sectorKey in sectors)) {
                sectors[sectorKey] = [];
            }

            sectors[sectorKey].push(item.subsectorKey);
        });

        obj.sectors = sectors;
        obj.sectorNames = sectorNames;

        return obj;
    }

    /** Returns a list of all sector keys. **/
    getSectorKeys() {
        return Object.keys(this.sectors);
    }

    /** Returns the sector or subsector name by its key. **/
    getSectorNameByKey(key: string) {
        if (!isEmpty(this.sectorNames)) {
            return this.sectorNames[key];
        }

        return '';
    }

    getSubsectorKeys(sectorKey: string) {
        return this.sectors[sectorKey];
    }
}