export class UserSubscription {
    subscriptionId: string | null = '';
    subscriptionStatus: string | null = '';
    isActive: boolean = false;
    productName: string | null = '';
    recurringInterval: string | null = '';
    amount: number | null = null;
    currentPeriodEndDate: string | null = null;
    cancelAtPeriodEnd: boolean = false;

    static fromJson(json: any): UserSubscription {
        const obj = new UserSubscription();

        obj.subscriptionStatus = json.subscription_status;
        obj.isActive = json.is_active;
        obj.currentPeriodEndDate = json.current_period_end_date;
        obj.cancelAtPeriodEnd = json.cancel_at_period_end;
        obj.productName = json.product_name;
        obj.recurringInterval = json.recurring_interval;
        obj.amount = json.amount;
        obj.subscriptionId = json.subscription_id;

        return obj;
    }

    toJson() {
        return {
            "subscription_status": this.subscriptionStatus,
            "is_active": this.isActive,
            "current_period_end_date": this.currentPeriodEndDate,
            "cancel_at_period_end": this.cancelAtPeriodEnd,
            "product_name": this.productName,
            "recurring_interval": this.recurringInterval,
            "amount": this.amount,
            "subscription_id": this.subscriptionId
        };
    }
}