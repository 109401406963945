import {Currency} from "./currency";

export class CurrencyList extends Array<Currency> {

    static fromJson(json: any[]): CurrencyList {
        const obj = new CurrencyList();

        json.forEach(function (item: any) {
            obj.push(Currency.fromJson(item));
        });

        return obj;
    }
}