import {Company} from "./company";

export class CompanyList extends Array<Company> {

    static fromJson(json: any[]): CompanyList {
        const obj = new CompanyList();

        json.forEach(function (item: any) {
            obj.push(Company.fromJson(item));
        });

        return obj;
    }

    getCompany(companyId: string): Company | null {

        if (companyId === '') {
            return null;
        }

        let company: Company | null = null;

        this.forEach(function (co: Company) {
            if (co.companyId === companyId) {
                company = co;
            }
        });

        if (company === null) {
            console.warn('CompanyId not found in company list');
        }

        return company;
    }

    toJson(): any[] {
        const results: any[] = [];

        this.forEach(function (company: Company) {
            results.push(company.toJson());
        });

        return results;
    }
}