import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ApiEndpoints} from '../../api/apiEndpoints';
import {userEmailChangeValidationSchema} from "../../api/user/userValidationSchema";
import FormError from "../../components/FormError";
import SubmitButton from "../../components/SubmitButton";
import usePostData from "../../hooks/usePostData";
import {PageTitles} from '../../locales/pageTitles';
import {getFieldToFocus} from "../../utils/pageUtils";
import {PageEndpoints} from '../pageEndpoints';
import {PageStatus} from "../pageStatus";

export default function UserPasswordChangeConfirmation() {

    const navigate = useNavigate();
    const {status, message, validationErrors, submitForm, formData} = usePostData();

    useEffect(() => {
        document.title = PageTitles.userResetPassword;
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userEmailChangeValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    /* Get email and code from link. */
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');
    const code = new URLSearchParams(search).get('code');

    /* Set defaults, if they exist. */
    useEffect(() => {
        reset({
            email_address: email,
            verification_code: code
        }, {keepDefaultValues: true});
    }, [reset, email, code]);

    useEffect(() => {
        const defaultField = 'email_address';

        if (status === PageStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, defaultField);
            setFocus(fieldName);

        } else if (status === PageStatus.IsReady) {
            setFocus(defaultField);
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === PageStatus.HasSubmitted) {
            navigate(PageEndpoints.userSignIn, {
                state: {
                    'emailAddress': formData.email_address,
                    'message': 'Your password has been changed.'
                }
            });
        }
    }, [navigate, status, formData]);

    const onSubmitHandler = async (data: any) => {
        submitForm(ApiEndpoints.userChangePassword, data);
    };

    return (
        <div className="container account py-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <h1 className="card-title">Change your password.</h1>
                    <FormError status={status} message={message}/>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <fieldset id="me" disabled={status === PageStatus.IsSubmitting}>
                            <div className="form-floating">
                                <input {...register('email_address')} autoFocus type="text" className="form-control"
                                       id="floatingEmail" placeholder="name@example.com"/>
                                <label htmlFor="floatingEmail">Email address</label>
                                <small className="text-danger">
                                    {errors?.email_address?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('verification_code')} type="text" className="form-control"
                                       id="floatingVerificationCode" placeholder="123456"/>
                                <label htmlFor="floatingVerificationCode">Verification code</label>
                                <small className="text-danger">
                                    {errors?.verification_code?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('password')} type="password" className="form-control"
                                       id="floatingPassword" placeholder="Password"/>
                                <label htmlFor="floatingPassword">New password</label>
                                <small className="text-danger">
                                    {errors?.password?.message?.toString()}
                                </small>
                            </div>
                            <div className="form-floating">
                                <input {...register('passwordConfirm')} type="password" className="form-control"
                                       id="floatingPasswordConfirm" placeholder="Password"/>
                                <label htmlFor="floatingPasswordConfirm">Re-enter new password</label>
                                <small className="text-danger">
                                    {errors?.passwordConfirm?.message?.toString()}
                                </small>
                            </div>
                            <div>
                                <SubmitButton
                                    isFormReady={isFormReady}
                                    featureStatus={status}
                                    text="Change password"
                                    additionalClassName="w-100"
                                />
                            </div>
                        </fieldset>
                    </form>
                    <div className="row">
                        <div className="col-6">
                            <Link to={PageEndpoints.userChangePasswordRequest} className="float-start">
                                Resend email
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link to={PageEndpoints.userSignIn} className="float-end">
                                Sign in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}