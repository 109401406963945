import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App';
import './custom.scss';
import './index.css';
import {AuthProvider} from "./AuthProvider";
import {CompanyProvider} from "./CompanyProvider";
import {AppIntegration} from "./pages/account/AppIntegration";
import {AnalysisRoot} from "./pages/analysis/AnalysisRoot";
import {PageEndpoints} from './pages/pageEndpoints';
import NotFound from "./pages/not_found/NotFound";
import NotFoundRoot from "./pages/not_found/NotFoundRoot";
import {CompanyRoot} from "./pages/companies/CompanyRoot";
import ContactUs from "./pages/public/ContactUs";
import Faqs from "./pages/public/Faqs";
import Home from "./pages/public/Home";
import Pricing from "./pages/public/Pricing";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import TermsOfUse from "./pages/public/TermsOfUse";
import {ReportRoot} from "./pages/reports/ReportRoot";
import {UserAccountRoot} from "./pages/account/UserAccountRoot";
import UserEmailVerification from './pages/account/UserEmailVerification';
import UserEmailVerificationRequest from './pages/account/UserEmailVerificationRequest';
import UserEmailVerificationStatus from './pages/account/UserEmailVerificationStatus';
import UserPasswordChangeConfirmation from './pages/account/UserPasswordChangeConfirmation';
import UserPasswordChangeRequest from './pages/account/UserPasswordChangeRequest';
import UserPasswordChangeStatus from './pages/account/UserPasswordChangeStatus';
import UserSignIn from "./pages/account/UserSignIn";
import UserSignOut from './pages/account/UserSignOut';
import UserSignUp from './pages/account/UserSignUp';
import UserSubscribe from "./pages/account/UserSubscribe";
import reportWebVitals from './reportWebVitals';
import RouteGuard from './RouteGuard';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    // <React.StrictMode>
    <AuthProvider>
        <CompanyProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        <Route path={PageEndpoints.home}
                               element={<Home/>}/>
                        <Route path={PageEndpoints.publicContactUs}
                               element={<ContactUs/>}/>
                        <Route path={PageEndpoints.publicFaqs}
                               element={<Faqs/>}/>
                        <Route path={PageEndpoints.publicPricing}
                               element={<Pricing/>}/>
                        <Route path={PageEndpoints.publicPrivacyPolicy}
                               element={<PrivacyPolicy/>}/>
                        <Route path={PageEndpoints.publicTermsOfUse}
                               element={<TermsOfUse/>}/>

                        <Route
                            path={PageEndpoints.company}
                            element={<RouteGuard><CompanyRoot/></RouteGuard>}
                        />

                        <Route
                            path={PageEndpoints.companyNew}
                            element={<RouteGuard><CompanyRoot/></RouteGuard>}
                        />

                        <Route
                            path={PageEndpoints.reports}
                            element={<RouteGuard><ReportRoot/></RouteGuard>}
                        />

                        <Route
                            path={PageEndpoints.reportsAdd}
                            element={<RouteGuard><ReportRoot/></RouteGuard>}
                        />

                        <Route
                            path={PageEndpoints.analysis}
                            element={<RouteGuard><AnalysisRoot/></RouteGuard>}
                        />

                        <Route path={PageEndpoints.userAccount}
                               element={<UserAccountRoot/>}/>
                        <Route path={PageEndpoints.userIntegration}
                               element={<AppIntegration/>}/>

                        <Route path={PageEndpoints.userSignIn} element={<UserSignIn/>}/>
                        <Route path={PageEndpoints.userSignOut} element={<UserSignOut/>}/>
                        <Route path={PageEndpoints.userSignUp} element={<UserSignUp/>}/>

                        <Route path={PageEndpoints.userEmailVerification}
                               element={<UserEmailVerification/>}/>
                        <Route path={PageEndpoints.userEmailVerificationRequest}
                               element={<UserEmailVerificationRequest/>}/>
                        <Route path={PageEndpoints.userEmailVerificationStatus}
                               element={<UserEmailVerificationStatus/>}/>

                        <Route path={PageEndpoints.userSubscription}
                               element={<UserSubscribe/>}/>

                        <Route path={PageEndpoints.userChangePasswordConfirmation}
                               element={<UserPasswordChangeConfirmation/>}/>
                        <Route path={PageEndpoints.userChangePasswordRequest}
                               element={<UserPasswordChangeRequest/>}/>
                        <Route path={PageEndpoints.userChangePasswordStatus}
                               element={<UserPasswordChangeStatus/>}/>

                        <Route path={PageEndpoints.notFound}
                               element={<NotFound/>}/>

                        <Route
                            path="*"
                            element={<NotFoundRoot/>}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </CompanyProvider>
    </AuthProvider>
    // {/*</React.StrictMode>*/}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
