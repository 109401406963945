import {KpiKey} from "./kpiKey";

export class Kpi implements KpiKey {
    kpiKey: string = '';
    kpiName: string = '';
    kpiNameLong: string = '';
    kpiValue: number | null = null;
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';

    static fromJson(json: any): Kpi {
        const obj = new Kpi();

        obj.kpiKey = json.kpi_key;
        obj.kpiName = json.kpi_name;
        obj.kpiNameLong = json.kpi_name_long;
        obj.kpiValue = json.kpi_value;
        obj.kpiCategoryKey = json.kpi_category_key;
        obj.kpiCategoryName = json.kpi_category_name;

        return obj;
    }
}