export class CompanyKpiPrognosesGetParams {
    companyId: string = '';
    periodEndDate: string = '';
    kpiCategoryKeys: string[] = [];

    toJson() {
        return {
            "company_id": this.companyId,
            "period_end_date": this.periodEndDate,
            "kpi_category_keys": this.kpiCategoryKeys
        };
    }
}