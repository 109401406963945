import * as React from 'react';
import {getPublicUrl} from "../utils/envUtils";

export default function Logo() {
    return (
        <div className="logo">
            <picture>
                <img src={getPublicUrl() + '/images/cfo-digital-logo.png'} alt="CFOdigital logo"/>
            </picture>
        </div>
    );
}