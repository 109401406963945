import * as React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';
import {Kpi} from "../../api/metrics/kpi";
import {KpiList} from "../../api/metrics/kpiList";
import {round} from '../../utils/numberUtils';

function Info(
    {id, title, kpiName}: {
        id: string,
        title: string,
        kpiName: string
    }) {

    if (title === kpiName) {
        return <></>;
    }

    return (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <i className="bi bi-info-circle"></i>
        </OverlayTrigger>
    );
}

export default function KpiGroup(
    {kpis, categoryName}: {
        kpis: KpiList,
        categoryName: string
    }) {

    return (
        <div className="card">
            <div className="card-header">
                <h3>{categoryName}</h3>
            </div>
            <div className="card-body">
                <table className="table">
                    <tbody>
                    {kpis.map((item: Kpi) => (
                        <tr key={item.kpiKey}>
                            <td>{item.kpiName} {<Info id={item.kpiKey} title={item.kpiNameLong}
                                                      kpiName={item.kpiName}></Info>}</td>
                            <td className="text-end">
                                {
                                    item.kpiValue === null ? 'N/A' : round(item.kpiValue, 5)
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
