import {KpiInfo} from "./kpiInfo";

export class KpiInfoList extends Array<KpiInfo> {

    static fromJson(json: any[]): KpiInfoList {
        const obj = new KpiInfoList();

        json.forEach(function (item: any) {
            obj.push(KpiInfo.fromJson(item));
        });

        return obj;
    }

    getKpiCategoryKey(kpiKey: string) {
        let categoryKey = '';

        this.forEach(function (item: KpiInfo) {
            if (item.kpiKey === kpiKey) {
                categoryKey = item.kpiCategoryKey;
                return;
            }
        });

        return categoryKey;
    }

    getKpiName(kpiKey: string): string {
        let name = '';

        this.forEach(function (item: KpiInfo) {
            if (item.kpiKey === kpiKey) {
                name = item.kpiName;
                return;
            }
        });

        return name;
    }

    getKpiNameLong(kpiKey: string): string {
        let name = '';

        this.forEach(function (item: KpiInfo) {
            if (item.kpiKey === kpiKey) {
                name = item.kpiNameLong;
                return;
            }
        });

        return name;
    }
}