import {Report} from "./report";

export class ReportList extends Array<Report> {

    static fromJson(json: any[]): ReportList {
        const obj = new ReportList();

        json.forEach(function (item: any) {
            obj.push(Report.fromJson(item));
        });

        return obj;
    }
}