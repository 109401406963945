export class AppIntegrationToken {
    accessToken: string = '';
    refreshToken: string = '';

    static fromJson(json: any) {
        const obj = new AppIntegrationToken();

        obj.accessToken = json.access_token;
        obj.refreshToken = json.refresh_token;

        return obj;
    }

    toJson() {
        return {
            "access_token": this.accessToken,
            "refresh_token": this.refreshToken
        };
    }
}