import * as React from 'react';
import {Strings} from "../../locales/strings";
import {PageStatus} from "../pageStatus";
import {KpiAggsList} from "./chartUtils";
import {ErrorCard} from "./ErrorCard";
import {KpiAggChart} from "./KpiAggChart";
import {LoadingCard} from "./LoadingCard";

export default function KpiAggCard(
    {title, status, data}: {
        title: string,
        status: PageStatus,
        data: KpiAggsList
    }) {

    let template = <></>;

    if (status === PageStatus.IsReady || status === PageStatus.IsSubmitting) {
        template = <LoadingCard description1={Strings.CHART_GENERATING_ANALYSIS}/>;
    } else if (status === PageStatus.HasSubmitted && data !== null) {
        template = <KpiAggChart data={data}/>;
    } else if (status === PageStatus.HasError) {
        template = <ErrorCard description2="Unable to load chart."/>;
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}
