export class UserEntitlement {
    entitlementKey: string = '';
    entitlementValue: any | null = null;

    static fromJson(json: any) {
        const obj = new UserEntitlement();

        obj.entitlementKey = json.entitlement_key;
        obj.entitlementValue = json.entitlement_value;

        return obj;
    }

    toJson() {
        return {
            "entitlement_key": this.entitlementKey,
            "entitlement_value": this.entitlementValue
        };
    }
}