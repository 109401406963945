import {ProductPrice} from "./productPrice";

export class ProductPriceList extends Array<ProductPrice> {

    static fromJson(json: any[]): ProductPriceList {
        const obj = new ProductPriceList();

        json.forEach(function (item: any) {
            obj.push(ProductPrice.fromJson(item));
        });

        return obj;
    }
}